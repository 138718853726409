import { EDITOR_URL, EDITOR_URL_V3 } from 'config/constants'
import { getImpersonatedUser } from 'features/userAuth/userAuthSlice'
import { useSelector } from 'react-redux'
import { Training } from 'features/trainingDetails/types'
import { isV3 } from 'utils/v3'
import { TrainingItem } from 'features/trainingsList/types'

const useEditorLink = (training: Training | TrainingItem) => {
  const impersonated = useSelector(getImpersonatedUser)
  const param = impersonated ? `?as=${impersonated.sub}` : ''
  const editorURL = isV3(training) ? EDITOR_URL_V3 : EDITOR_URL
  let link = `${editorURL}/${training.id}` + param
  return link
}

export default useEditorLink
