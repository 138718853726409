import React from 'react'
import { ControlGroup, InputGroup, Button } from '@blueprintjs/core'
import cx from 'classnames'
import CopyToClipboardButton from 'lib/ui-components/components/buttons/CopyToClipboardButton'
import ActivatedButton from 'lib/ui-components/components/buttons/ActivatedButton'

const EditableTextElementView: React.FC<any> = ({
  id,
  initialValue,
  className,
  isLoading,
  onSave,
  copyToClipboard,
  isEditing,
  onEditing,
  value,
  onSetValue,
  changeHandler,
  savedFeedback,
}) => {
  return (
    <div
      className={cx(className, 'editable-element', {
        'has-changed': isEditing,
      })}
    >
      <ControlGroup className="editable-container">
        <InputGroup
          id={id}
          value={value}
          onChange={changeHandler}
          onClick={() => onEditing(true)}
          onBlur={() => onEditing(false)}
          fill
        />
        <>
          {value !== initialValue && !isLoading ? (
            <Button
              className="button-icon"
              icon="cross"
              onClick={() => {
                onSetValue(initialValue)
              }}
            ></Button>
          ) : undefined}
          {value !== initialValue || savedFeedback ? (
            <ActivatedButton
              id={`${id}-confirm`}
              isActivated={savedFeedback}
              isLoading={isLoading}
              onClick={() => {
                onSave(value)
              }}
            />
          ) : (
            copyToClipboard && (
              <CopyToClipboardButton id={`${id}-copy`} text={value} />
            )
          )}
        </>
      </ControlGroup>
    </div>
  )
}

export default EditableTextElementView
