import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'
import { RootState } from 'app/rootReducer'
import { TrainingItem } from 'features/trainingsList/types'
import * as api from 'api/manager'
import { updateUserStats } from 'lib/aucta-backend/stats/api'
import { isTargetUserImpersonated } from 'api/credentials'

interface TrainingListState {
  trainings: TrainingItem[]
}

const initialState: TrainingListState = {
  trainings: [],
}

const trainingsList = createSlice({
  name: 'trainingsList',
  initialState,
  reducers: {
    setTrainingList(state, action: PayloadAction<TrainingItem[]>) {
      state.trainings = action.payload
    },
    removeTraining(state, action: PayloadAction<string>) {
      const id: string = action.payload
      state.trainings = state.trainings.filter(t => t.id !== id)
    },
  },
})

export default trainingsList

// -------------------------
// selectors

export const getTrainings = (state: RootState): TrainingItem[] =>
  state.trainingsList.trainings

// -------------------------
// async actions

export const fetchTrainings =
  (user?: string): AppThunk =>
  async dispatch => {
    const trainings: TrainingItem[] =
      (await api.getTrainings()) as TrainingItem[]
    dispatch(trainingsList.actions.setTrainingList(trainings))
  }

export const deleteTraining =
  (id: string): AppThunk =>
  async dispatch => {
    await api.deleteTraining(id)
    dispatch(trainingsList.actions.removeTraining(id))
    if (!isTargetUserImpersonated()) updateUserStats('deletions')
  }

export const createTraining =
  (id: string, v3 = false): AppThunk =>
  async dispatch => {
    await api.createTraining(id, undefined, v3)
    dispatch(fetchTrainings())
    if (!isTargetUserImpersonated()) updateUserStats('creations')
  }

export const copyTraining =
  (id: string, newId: string, targetUser?: string): AppThunk =>
  async dispatch => {
    await api.copyTraining(id, newId, targetUser)
    dispatch(fetchTrainings())
    if (!isTargetUserImpersonated()) updateUserStats('creations')
  }
