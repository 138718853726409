import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import 'lib/ui-components/components/buttons/link-button.scss'

const LinkButton = ({ icon, text, to, className }) => {
  return (
    <Link to={to} className={cx('link-button', { [className]: className })}>
      <Icon icon={icon} />
      <span className="text">{text}</span>
    </Link>
  )
}

LinkButton.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
}

export default LinkButton
