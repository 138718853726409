import React from 'react'
import { ControlGroup, InputGroup, Button } from '@blueprintjs/core'
import CopyToClipboardButton from 'lib/ui-components/components/buttons/CopyToClipboardButton'
import ActivatedButton from 'lib/ui-components/components/buttons/ActivatedButton'
import NotEditingLink from 'ui/editable-elements/NotEditingLink'

const EditableLinkElementView: React.FC<any> = ({
  id,
  value,
  isLoading = false,
  leftElement = undefined,
  onChange,
  domain,
  link,
  onSave,
  onToggleEditing,
  isEditing,
  savedFeedback,
}) => {
  return (
    <div className="editable-linkContainer">
      {isEditing || isLoading || savedFeedback ? (
        <ControlGroup className="editable-link">
          {isEditing ? (
            <InputGroup
              id={`${id}`}
              value={value}
              onChange={onChange}
              leftElement={leftElement}
              fill
            />
          ) : (
            <NotEditingLink domain={domain} link={link} />
          )}

          <Button
            className="button-icon"
            icon="cross"
            onClick={onToggleEditing}
          ></Button>

          <ActivatedButton
            id={`${id}-confirm`}
            isActivated={savedFeedback}
            onClick={onSave}
            isLoading={isLoading}
          />
        </ControlGroup>
      ) : (
        <div className="editable-linkBox">
          <NotEditingLink domain={domain} link={link} />
          <Button
            id={`${id}-edit`}
            className="button-icon"
            icon="edit"
            onClick={onToggleEditing}
            loading={isLoading}
          />

          <CopyToClipboardButton text={`${domain}/${link}`} />
        </div>
      )}
    </div>
  )
}

export default EditableLinkElementView
