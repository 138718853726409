import React from 'react'
import PropTypes from 'prop-types'
import { Tab, Tabs, Classes } from '@blueprintjs/core'
import 'lib/stats-graphs/components/chart-boxes/chart-box.scss'
import cx from 'classnames'

const CompositionChartBox = ({
  title,
  totalSlot,
  historicSlot,
  onChangeTab,
  selectedTabId,
  isLoading,
}) => {
  return (
    <div
      className={cx('chartBox composition', {
        [Classes.SKELETON]: isLoading,
      })}
    >
      <div className="chartBox-header">
        <span className="chartBox-title">{title}</span>
      </div>
      <div className="chartBox-content">
        <Tabs
          id="chartTabs"
          onChange={onChangeTab}
          selectedTabId={selectedTabId}
          renderActiveTabPanelOnly
        >
          <Tab id="total" title="Total" panel={totalSlot} />
          <Tab id="historic" title="Historic" panel={historicSlot} />
        </Tabs>
      </div>
    </div>
  )
}
CompositionChartBox.propTypes = {
  title: PropTypes.string,
  totalSlot: PropTypes.node,
  historicSlot: PropTypes.node,
  onChangeTab: PropTypes.func,
  selectedTabId: PropTypes.oneOf(['total', 'historic']),
  isLoading: PropTypes.bool,
}

export default CompositionChartBox
