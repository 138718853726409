import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { EditableTrainingProps } from 'features/trainingDetails/types'
import {
  getTraining,
  getTrainingStats,
  fetchTraining,
  fetchTrainingStats,
  updateTraining,
  resetTraining,
} from 'features/trainingDetails/trainingDetailsSlice'
import TrainingDetailsPageView from 'features/trainingDetails/TrainingDetailsPageView'
import { isDataEmpty, encodeGraphData } from 'features/stats/utils/data'
import TrainingDetailsPageViewV3 from './TrainingDetailsPageViewV3'

const rangeValues: { [key: string]: number } = {
  '1-day': 1,
  '1-week': 7,
  '1-month': 30,
  '3-months': 90,
  '6-months': 180,
  '1-year': 365,
}

// -------------------------
// connection logic: queries

function useEffectFetchTrainingDetails(id: string, range: string): boolean {
  const [isLoading, setLoading] = useState<boolean>(true)
  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      try {
        await dispatch(fetchTraining(id))
      } catch (err) {
        console.error('Error fetching training:', err)
        if (
          err.message === 'Invalid training' ||
          err.response?.data?.error === 'The specified key does not exist.'
        ) {
          history.push('/not-found')
        } else {
          throw err
        }
      }

      setLoading(false)
    }
    fetch()
  }, [id, dispatch, setLoading, history])
  return isLoading
}

function useEffectFetchTrainingStats(id: string, range: string): boolean {
  const [isLoading, setLoading] = useState<boolean>(true)
  const dispatch = useDispatch()
  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      await dispatch(fetchTrainingStats(id, rangeValues[range]))
      setLoading(false)
    }
    fetch()
  }, [id, dispatch, setLoading, range])
  return isLoading
}

// -------------------------
// connection logic: commands

function useUpdateTraining(id: string) {
  const dispatch = useDispatch()
  return useCallback(
    async (values: EditableTrainingProps) => {
      await dispatch(updateTraining(id, values))
    },
    [id, dispatch],
  )
}

// -------------------------
// ui component

const TrainingDetailsPage: React.FC<{}> = () => {
  const { id } = useParams() as { id: string }
  const [range, setRange] = useState('1-week')
  const isDetailsLoading = useEffectFetchTrainingDetails(id, range)
  const isStatsLoading = useEffectFetchTrainingStats(id, range)
  const training = useSelector(getTraining)
  const stats = useSelector(getTrainingStats)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetTraining())
    }
  }, [])

  const onChangeTraining = useUpdateTraining(id)
  const onChangeRange = (ev: any) => {
    const newRange = ev.target.value
    setRange(newRange)
  }

  const PageView =
    training.version === 'v3'
      ? TrainingDetailsPageViewV3
      : TrainingDetailsPageView

  const graphData = isDataEmpty(stats) ? {} : encodeGraphData(stats)

  return (
    <PageView
      key={training.id}
      training={training}
      trainingStats={graphData}
      isDetailsLoading={isDetailsLoading}
      isStatsLoading={isStatsLoading}
      selectedRange={range}
      onChangeRange={onChangeRange}
      onChangeTraining={onChangeTraining}
    />
  )
}

export default TrainingDetailsPage
