import React from 'react'
import PropTypes from 'prop-types'
import 'lib/stats-graphs/components/chart-boxes/chart-box.scss'
import { Classes } from '@blueprintjs/core'
import cx from 'classnames'

const ChartBox = ({ title, value, children, isLoading }) => {
  return (
    <div
      className={cx('chartBox', {
        [Classes.SKELETON]: isLoading,
      })}
    >
      <div className="chartBox-header">
        <span className="chartBox-title" title={title}>
          {title}
        </span>
        <span className="chartBox-value">{value}</span>
      </div>
      <div className="chartBox-content">{children}</div>
    </div>
  )
}
ChartBox.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  isLoading: PropTypes.bool,
}

export default ChartBox
