import React from 'react'
import { Intent } from '@blueprintjs/core'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Toaster from 'features/toasterService/Toaster'
import PasswordRecoveryPageView, {
  PasswordRecoveryFormValues,
} from 'features/userAuth/PasswordRecoveryPageView'

import { recoverPasswordAction } from 'features/userAuth/userAuthSlice'

// -------------------------
// form logic

const initialValues: PasswordRecoveryFormValues = {
  email: '',
}

function useHandleSubmit() {
  const dispatch = useDispatch()
  const history = useHistory()
  return async (
    values: PasswordRecoveryFormValues,
    { setSubmitting }: { setSubmitting: (arg: boolean) => void },
  ) => {
    setSubmitting(true)
    try {
      await dispatch(recoverPasswordAction(values.email))
      Toaster.show({
        icon: 'envelope',
        intent: Intent.PRIMARY,
        message: 'Email with reset code sent.',
      })
      history.push('/auth/password-reset')
    } catch (e) {
      Toaster.show({
        icon: 'error',
        intent: Intent.DANGER,
        message: e.message,
      })
      setSubmitting(false)
    }
  }
}

// -------------------------
// ui component

const PasswordRecoveryPage: React.FC<{}> = () => {
  const handleSubmit = useHandleSubmit()
  return (
    <PasswordRecoveryPageView
      initialValues={initialValues}
      onSubmit={handleSubmit}
    />
  )
}

export default PasswordRecoveryPage
