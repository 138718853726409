import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'
import Auth from 'lib/aucta-backend/auth'
import { RootState } from 'app/rootReducer'
import { getUser, getUsers, updateUserAttributes } from 'api/manager'
import { setTargetUser } from 'api/credentials'
import { updateUserStats } from 'lib/aucta-backend/stats/api'
import { User, EditableCognitoAttributes, APIUser } from './types'
import { mapKeys, mapValues } from 'lodash'
import store from 'app/store'

interface UserAuthState {
  currentUser: User | null
  currentSession: any | null
  impersonatedUser: User | null
  userList?: any[] | null
  error?: string | null
}

const initialState: UserAuthState = {
  currentUser: null,
  currentSession: null,
  impersonatedUser: null,
}

const userAuth = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    setSession(state: UserAuthState, action: PayloadAction<any>) {
      state.currentSession = action.payload
    },
    setUser(state: UserAuthState, action: PayloadAction<User>) {
      state.currentUser = action.payload
      state.error = null
    },
    unsetUser(state: UserAuthState) {
      state.currentUser = null
    },
    unsetSession(state: UserAuthState) {
      state.currentSession = null
    },
    setUserList(state: UserAuthState, action: PayloadAction<User[]>) {
      state.userList = action.payload
    },
    setImpersonatedUser(
      state: UserAuthState,
      action: PayloadAction<User | null>,
    ) {
      state.impersonatedUser = action.payload
    },
  },
})

export default userAuth

// -------------------------
// setters

export const setImpersonatedUser =
  (impersonatedUser: User | null): AppThunk =>
  async dispatch => {
    const user = impersonatedUser
      ? formatAPIUser(await getUser(impersonatedUser.sub))
      : null
    setTargetUser(user)
    dispatch(userAuth.actions.setImpersonatedUser(user))
  }

// -------------------------
// selectors

export const getCurrentUser = (state: RootState) => state.userAuth.currentUser
export const getCurrentSession = (state: RootState) =>
  state.userAuth.currentSession
export const getUserList = (state: RootState) => state.userAuth.userList
export const getImpersonatedUser = (state: RootState) =>
  state.userAuth.impersonatedUser
export const getTargetUser = (state: RootState) =>
  state.userAuth.impersonatedUser || state.userAuth.currentUser

// -------------------------
// async actions

export const fetchAuthenticatedUser = (): AppThunk => async dispatch => {
  const amplifyUser = await Auth.currentAuthenticatedUser()
  const user = formatAPIUser(await amplifyToAPIUser(amplifyUser))
  dispatch(
    userAuth.actions.setSession(amplifyUser.signInUserSession.idToken.payload),
  )
  dispatch(userAuth.actions.setUser(user))
}

export const fetchImpersonatedUser =
  (userId: string): AppThunk =>
  async dispatch => {
    const user = formatAPIUser(await getUser(userId))
    dispatch(userAuth.actions.setImpersonatedUser(user))
  }

export const fetchTargetUser = (): AppThunk => async dispatch => {
  const state = store.getState()
  const targetUser = getTargetUser(state)
  const impersonatedUser = getImpersonatedUser(state)
  if (!targetUser) return
  const user = formatAPIUser(await getUser(targetUser.sub))
  if (impersonatedUser && targetUser?.sub === impersonatedUser.sub) {
    dispatch(userAuth.actions.setImpersonatedUser(user))
  } else {
    dispatch(userAuth.actions.setUser(user))
  }
}

export const fetchUserList = (): AppThunk => async dispatch => {
  const users = (await getUsers()).map(formatAPIUser)
  dispatch(userAuth.actions.setUserList(users))
}

export const registerUserAction =
  (
    email: string,
    password: string,
    name: string,
    surname: string,
    company: string,
  ): AppThunk =>
  async () => {
    await Auth.signUp(email, password, name, surname, company)
  }

export const loginUserAction =
  (email: string, password: string): AppThunk =>
  async dispatch => {
    const amplifyUser = await Auth.signIn(email, password)
    const user = formatAPIUser(await amplifyToAPIUser(amplifyUser))
    await updateUserStats('logins', user.sub)
    dispatch(
      userAuth.actions.setSession(
        amplifyUser.signInUserSession.idToken.payload,
      ),
    )
    dispatch(userAuth.actions.setUser(user))
  }

export const recoverPasswordAction =
  (email: string): AppThunk =>
  async () => {
    await Auth.forgotPassword(email)
  }

export const resetPasswordAction =
  (email: string, code: string, password: string): AppThunk =>
  async () => {
    const resp = await Auth.recoveryChangePassword(email, code, password)
    console.log('> RESP:', resp)
  }

export const logoutUserAction = (): AppThunk => async dispatch => {
  await Auth.signOut()
  dispatch(userAuth.actions.unsetUser)
  dispatch(userAuth.actions.unsetSession)
}

export const saveCognitoAttributes =
  (userId: string, attrs: EditableCognitoAttributes): AppThunk =>
  async dispatch => {
    const formated = formatEditableCognitoAttributes(attrs)
    await updateUserAttributes(userId, formated)
    dispatch(fetchTargetUser())
  }

// utils
function formatAPIUser(user: APIUser): User {
  return {
    sub: getAttr(user, 'sub'),
    name: getAttr(user, 'name'),
    family_name: getAttr(user, 'family_name'),
    email: getAttr(user, 'email'),
    email_verified: getAttr(user, 'email_verified'),
    paidAccount: getAttr(user, 'custom:paid-account'),
    company: getAttr(user, 'custom:company'),
    department: getAttr(user, 'custom:department'),
    endOfTrial: getAttr(user, 'custom:end-of-trial'),
    signUpDate: user.UserCreateDate,
    isAdmin: user.isAdmin || false,
    isEnabled: user.Enabled,
  }
}

function formatEditableCognitoAttributes(attrs: EditableCognitoAttributes) {
  const mappings: { [key: string]: string } = {
    paidAccount: 'custom:paid-account',
    company: 'custom:company',
    department: 'custom:department',
    endOfTrial: 'custom:end-of-trial',
  }
  const formatedKeys = mapKeys(attrs, (_, key) => mappings[key] || key)
  const formatedValues = mapValues(formatedKeys, value =>
    value === true ? 'true' : value === false ? 'false' : value,
  )
  return formatedValues
}

async function amplifyToAPIUser(amplifyUser: any) {
  const user = await getUser(amplifyUser.username)
  return user
}

export function getAttr(user: APIUser, key: string) {
  if (!user) return undefined
  const attr = user.Attributes.find((attr: any) => attr.Name === key)
  return parseBool(attr?.Value)
}

function parseBool(input: string): any {
  return input === 'true' ? true : input === 'false' ? false : input
}
