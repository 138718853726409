import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@blueprintjs/core'
import cx from 'classnames'
import 'lib/ui-components/components/banners/banner.scss'

const Banner = ({ icon, text, position, status, className }) => {
  return (
    <div
      className={cx('banner', {
        [`banner-${position}`]: position,
        [`banner-${status}`]: status,
        [className]: className,
      })}
    >
      <div className="wrapper">
        {icon ? <Icon icon={icon} /> : null}
        <span className="banner-text">{text}</span>
      </div>
    </div>
  )
}

Banner.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.any,
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(['top'])]),
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(['error'])]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default Banner
