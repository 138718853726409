import React, { useCallback, useState, useEffect } from 'react'
import { TrainingItem } from '../types'
import { useDispatch } from 'react-redux'
import { EditableTrainingProps } from 'api/manager'
import { updateTraining } from 'features/trainingDetails/trainingDetailsSlice'
import { fetchTrainings } from 'features/trainingsList/trainingsListSlice'
import useEditorLink from 'hooks/useEditorLink'
import TrainingsListItemView from 'features/trainingsList/components/TrainingsListItemView'
import usePlayerLink from 'hooks/usePlayerLink'

// Types

interface TrainingItemProps {
  training: TrainingItem
  onDelete: (id: string) => Promise<void>
  onCopy: (id: string) => Promise<void>
  isLocked: boolean
}

// ui logic

function useDeleteItemCallback(
  id: string,
  onDelete: (id: string) => Promise<void>,
): [boolean, () => void] {
  const [isLoading, setLoading] = useState<boolean>(false)
  const handleDeleteItem = useCallback(async () => {
    setLoading(true)
    await onDelete(id)
    setLoading(false)
  }, [id, onDelete, setLoading])
  return [isLoading, handleDeleteItem]
}

function useLockItemCallback(
  isLocked: boolean,
  trainingId: string,
): [boolean, () => void] {
  const updateTraining = useUpdateTraining(trainingId)
  const [isLockLoading, setIsLockLoading] = useState(false)
  const dispatch = useDispatch()
  const handleLock = useCallback(async () => {
    setIsLockLoading(true)
    if (isLocked) await updateTraining({ locked: false })
    else await updateTraining({ locked: true })
    dispatch(fetchTrainings())
    setIsLockLoading(false)
  }, [isLocked, updateTraining, dispatch])
  return [isLockLoading, handleLock]
}

function useUpdateTraining(id: string) {
  const dispatch = useDispatch()
  return useCallback(
    async (values: EditableTrainingProps) => {
      await dispatch(updateTraining(id, values))
    },
    [id, dispatch],
  )
}

// Component

const TrainingsListItem: React.FC<TrainingItemProps> = ({
  training,
  onDelete,
  onCopy,
  isLocked,
}: TrainingItemProps) => {
  const editorLink = useEditorLink(training)
  const link = usePlayerLink(training)
  const [isLockLoading, handleLock] = useLockItemCallback(isLocked, training.id)
  const [isItemLocked, setItemLocked] = useState(isLocked)

  useEffect(() => {
    setItemLocked(isLocked)
  }, [isLocked])

  const onLockClicked = () => {
    handleLock()
    if (isLocked) setItemLocked(false)
    else setItemLocked(true)
  }

  const [isLoading, handleDeleteItem] = useDeleteItemCallback(
    training.id,
    onDelete,
  )

  return (
    <TrainingsListItemView
      training={training}
      isLoading={isLoading}
      onDeleteItem={handleDeleteItem}
      onCopy={onCopy}
      isLockLoading={isLockLoading}
      isItemLocked={isItemLocked}
      onLockClick={onLockClicked}
      link={link}
      editorLink={editorLink}
    />
  )
}

export default TrainingsListItem
