import React from 'react'
import ProtectedContent from 'ui/meta/ProtectedContent'
import Header from 'ui/headers/Header'
import MainLayout from 'lib/ui-components/components/layouts/MainLayout'

const MainLayoutContainer: React.FC<{
  activeButton?: any
  userSelected?: boolean
  children: any
}> = ({ children, activeButton, userSelected }) => (
  <ProtectedContent>
    <MainLayout
      headerSlot={
        <Header activeButton={activeButton} userSelected={userSelected} />
      }
    >
      {children}
    </MainLayout>
  </ProtectedContent>
)

export default MainLayoutContainer
