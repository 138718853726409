import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Radio = ({
  onChange,
  onBlur,
  textError,
  label,
  name,
  value,
  checked,
  isDisabled,
  className,
}) => {
  const classes = cx('radio', {
    [className]: className,
    'has-error': textError,
    'is-disabled': isDisabled,
  })
  return (
    <div className={classes}>
      <input
        className="radio-input"
        type="radio"
        id={label}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        checked={checked}
        disabled={isDisabled}
      />
      <label className="radio-label" htmlFor={label}>
        <span className="radio-text">{label}</span>
      </label>
      {textError ? <span className="radio-error">{textError}</span> : null}
    </div>
  )
}

Radio.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  textError: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.any,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
}

export default Radio
