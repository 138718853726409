import React from 'react'
import cx from 'classnames'
import UserSelect from 'lib/ui-components/components/selects/UserSelect'
import { useHistory } from 'react-router-dom'
import {
  Navbar,
  NavbarGroup,
  Button,
  Alignment,
  Menu,
  MenuItem,
  Icon,
} from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { ReactComponent as IconUser } from 'lib/ui-components/globals/assets/icons/icon-user.svg'

const MainNav: React.FC<any> = ({
  activeButton,
  userSelected,
  user,
  userList,
  impersonatedUser,
  onUserSelect,
  navButtonHandler,
  documentationLink,
}) => {
  const history = useHistory()

  return (
    <Navbar className="nav">
      <NavbarGroup align={Alignment.LEFT} className="nav-group">
        <Button
          onClick={() => navButtonHandler('overview')}
          icon="home"
          className={cx('nav-link', {
            'is-active': activeButton === 'overview',
          })}
        >
          Overview
        </Button>
        <Button
          id="stats-page-button"
          onClick={() => navButtonHandler('stats')}
          icon="timeline-area-chart"
          className={cx('nav-link', {
            'is-active': activeButton === 'stats',
          })}
        >
          Stats
        </Button>
      </NavbarGroup>
      <a
        className="button-outlined"
        href={documentationLink}
        target="_blank"
        rel="noopener noreferrer"
        title="creator documentation"
      >
        <Icon icon="document" />

        <span className="button-text">Creator documentation</span>
      </a>
      {user && user.isAdmin && userList && (
        <UserSelect
          id="impersonation-select"
          currentUser={impersonatedUser || user}
          users={userList}
          onSelect={onUserSelect}
        />
      )}

      <Popover2
        placement="bottom-end"
        content={
          <Menu>
            <MenuItem
              icon="settings"
              text="Settings"
              onClick={() => history.push('/settings')}
            />
            <MenuItem
              id="logout-button"
              icon="log-out"
              text="Logout"
              href="/auth/logout"
            />
          </Menu>
        }
      >
        <Button
          id="user-options-button"
          icon={<IconUser />}
          className={cx('button-icon', {
            'is-selected': userSelected,
          })}
        />
      </Popover2>
    </Navbar>
  )
}

export default MainNav
