import React from 'react'
import { Button } from '@blueprintjs/core'
import DialogLayout from 'lib/ui-components/components/dialogs/DialogLayout'

type Props = {
  isOpen: any
  onClose: () => void
  onDelete: (...args: [any]) => void
  isLoading: boolean
}

const DialogDeleteTraining: React.FC<Props> = ({
  isOpen,
  onClose,
  onDelete,
  isLoading,
}) => {
  return (
    <DialogLayout
      isOpen={isOpen}
      onClose={onClose}
      icon="trash"
      title="Delete training session"
      status="danger"
      isLoading={isLoading}
      spinnerIntent="danger"
      body={
        <>
          <p>
            Are you sure you want to delete this training session? This action
            is irreversible.
          </p>
        </>
      }
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            id="detail-delete-confirm-button"
            onClick={onDelete}
            intent="danger"
          >
            Delete training session
          </Button>
        </>
      }
    />
  )
}

export default DialogDeleteTraining
