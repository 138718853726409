import React, { useCallback } from 'react'
import MainLayoutContainer from 'ui/layouts/MainLayoutContainer'
import { TrainingItem } from 'features/trainingsList/types'
import SpinnerOverlay from 'lib/ui-components/components/spinners/SpinnerOverlay'
import PageHeading from 'lib/ui-components/components/headings/PageHeading'
import TrainingsList from 'features/trainingsList/TrainingsList'
import TrainingsListActions from 'features/trainingsList/components/TrainingsListActions'
import VideoTutorial from 'ui/videos/VideoTutorial'
import cx from 'classnames'
import SortBySelect from 'lib/ui-components/components/selects/SortBySelect'
import DropdownButton from 'lib/ui-components/components/buttons/DropdownButton'
import { ReactComponent as IconAucta } from 'lib/ui-components/globals/assets/icons/icon-aucta.svg'
import { partial, partialRight } from 'lodash'

export interface TrainingsListProps {
  trainings: TrainingItem[]
  onDelete: (id: string) => Promise<void>
  onCreate: (v3?: boolean) => Promise<void>
  onCopy: (id: string) => Promise<void>
  onSortChange: (state: string) => void
  isLoading: boolean
  isCreatingTraining: boolean
  isCopyingTraining: boolean
  sortingOptions: { value: string; text: string }[]
}

const TrainingsListPageView: React.FC<TrainingsListProps> = ({
  trainings,
  onDelete,
  onCreate,
  onCopy,
  onSortChange,
  isLoading,
  isCreatingTraining,
  isCopyingTraining,
  sortingOptions,
}) => {
  console.log('!2 trainings', trainings)
  return (
    <MainLayoutContainer activeButton="overview">
      {(isLoading || isCopyingTraining) && <SpinnerOverlay opaque />}
      <div id="overview-page" className={cx('overview-page')}>
        <PageHeading title="Overview">
          <DropdownButton
            buttonIcon="add"
            loading={isCreatingTraining}
            buttonText="New 3D visualization"
            intent="primary"
            onClick={() => onCreate(true)}
            items={[
              {
                text: 'Use Import Editor',
                icon: 'cube',
                onClick: () => onCreate(false),
              },
              {
                text: 'Use AUCTA Web Editor',
                icon: <IconAucta />,
                onClick: useCallback(() => onCreate(true), [onCreate]),
              },
            ]}
          />
        </PageHeading>
        <SortBySelect
          onSelect={ev => onSortChange(ev.target.value)}
          sortingOptions={sortingOptions}
        />
        <TrainingsList
          onDelete={onDelete}
          onCopy={onCopy}
          trainings={trainings}
        />
        <TrainingsListActions
          onCreate={onCreate}
          loading={isCreatingTraining}
        />
      </div>
      <VideoTutorial />
    </MainLayoutContainer>
  )
}

export default TrainingsListPageView
