import React from 'react'
import { Icon, Spinner } from '@blueprintjs/core'
import cx from 'classnames'

type Props = {
  disabled: boolean
  onclick: () => void
  extraClass: string
  icon: any
  loading: boolean
  title: string
  className?: any
}

const TrainingsListAction: React.FC<Props> = ({
  disabled,
  onclick,
  icon,
  extraClass,
  loading,
  title,
  className,
}) => {
  return loading ? (
    <Spinner intent="primary" size={16} />
  ) : (
    <div
      className={cx('training-itemAction', {
        'is-disabled': disabled,
        [className]: className,
      })}
      onClick={onclick}
      title={title}
    >
      <Icon icon={icon} className={extraClass} />
    </div>
  )
}

export default TrainingsListAction
