import React, { useEffect, useState } from 'react'
import { Button, MenuItem } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import 'lib/ui-components/components/selects/user-select.scss'

const renderUser = (user, { handleClick, modifiers }) => {
  //To be able to use it in js the next check had ".matchesPredicate"
  // removed since it is a thing to do with ts. Don't know if this will
  // cause any errors or this is just enough to prevent errors in the MenuItem
  if (!modifiers) {
    return null
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      text={user.email.toString()}
      key={user.email}
      onClick={handleClick}
    />
  )
}

const UserSelect = ({ id = '', users, currentUser, onSelect }) => {
  const restoreCurrentUser = () => {
    onSelect(null)
  }

  const [selectedItem, setSelectedItem] = useState(currentUser)
  useEffect(() => setSelectedItem(currentUser), [currentUser])

  const handlePredicate = (query, items) => {
    if (query !== '') setSelectedItem(undefined)
    else setSelectedItem(currentUser)
    return items.filter(i => i.email.includes(query))
  }

  return (
    <div className="select-user">
      <Select
        className="select"
        activeItem={selectedItem}
        items={users}
        itemRenderer={renderUser}
        itemListPredicate={handlePredicate}
        onItemSelect={onSelect}
        noResults={<MenuItem disabled={true} text="No results." />}
        popoverProps={{ usePortal: false }}
      >
        <Button
          id={id}
          text={currentUser.email}
          rightIcon="caret-down"
          minimal
        />
      </Select>
      <Button
        id={`${id}-cancel`}
        icon="cross"
        className="select-cancel"
        onClick={restoreCurrentUser}
      />
    </div>
  )
}

export default UserSelect
