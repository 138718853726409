import React, { useState, useCallback } from 'react'
import { Switch } from '@blueprintjs/core'
import Toaster from 'features/toasterService/Toaster'
import { Training } from 'features/trainingDetails/types'
import ActionButton from 'lib/ui-components/components/buttons/ActionButton'
import ActionButtonLink from 'lib/ui-components/components/buttons/ActionButtonLink'
import { useHistory } from 'react-router-dom'
import DialogShareTraining from 'ui/dialogs/DialogShareTraining'
import DialogLockTraining from 'ui/dialogs/DialogLockTraining'
import DialogDuplicateTraining from 'ui/dialogs/DialogDuplicateTraining'
import DialogDeleteTraining from 'ui/dialogs/DialogDeleteTraining'
import DialogMoveTraining from 'ui/dialogs/DialogMoveTraining'
import {
  copyTraining,
  deleteTraining,
} from 'features/trainingsList/trainingsListSlice'
import LinkButton from 'lib/ui-components/components/buttons/LinkButton'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from 'features/userAuth/userAuthSlice'
import useEditorLink from 'hooks/useEditorLink'
import useEmbedLink from 'hooks/useEmbedLink'
import TrainingDetailsField from 'features/trainingDetails/components/TrainingDetailsField'
import TrainingDetailsThumbnail from 'features/trainingDetails/components/TrainingDetailsThumbnail'
import { DEFAULT_TRAINING_THUMBNAIL } from 'config/constants'
import TrainingDetailsFieldLink from './components/TrainingDetailsFieldLink'
import { env } from 'lib/aucta-backend/env'
import EditableTextElement from 'ui/editable-elements/EditableTextElement'

interface Props {
  isLoading: boolean
  onChange: (value: any) => void
  training: Training
}

const useEditableTrainingProp = (
  propName: keyof Training,
  onChange: any,
): [(_: any) => Promise<void>, boolean] => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const updateValue = async (newValue: any) => {
    setLoading(true)

    try {
      await onChange({ [propName]: newValue })
      Toaster.show({
        icon: 'tick',
        intent: 'primary',
        message: 'Training updated successfully',
      })
    } catch (e) {
      const message = e.response?.data?.error || e.message
      Toaster.show({
        icon: 'error',
        intent: 'danger',
        message: `Error while saving: ${message}`,
      })
    }
    setLoading(false)
  }
  return [updateValue, isLoading]
}

const useDeleteTraining = (): [(id: string) => void, boolean] => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const removeTraining = useCallback(
    async id => {
      try {
        setIsLoading(true)
        await dispatch(deleteTraining(id))
        setIsLoading(false)
        history.push('/')
      } catch (err) {
        setIsLoading(false)
        Toaster.show({
          icon: 'error',
          intent: 'danger',
          message: `Error while saving: ${err.message}`,
        })
      }
    },
    [dispatch],
  )
  return [removeTraining, isLoading]
}

const useDuplicateTraining = (): [
  (id: string, newId: string) => void,
  boolean,
] => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const duplicateTraining = useCallback(
    async (id, newId) => {
      // const newId = Math.random().toString(36).substr(2, 9)
      try {
        setIsLoading(true)
        await dispatch(copyTraining(id, newId))
        setIsLoading(false)
        // setDuplicatedTrainingId(newId)
        // setShowModal('duplicate')
      } catch (err) {
        setIsLoading(false)
        Toaster.show({
          icon: 'error',
          intent: 'danger',
          message: `Error while saving: ${err.message}`,
        })
      }
    },
    [dispatch],
  )
  return [duplicateTraining, isLoading]
}

const TrainingDetailsOverview: React.FC<Props> = ({
  isLoading,
  onChange,
  training,
}) => {
  const editorLink = useEditorLink(training)
  const embedLink = useEmbedLink(training)
  const user = useSelector(getCurrentUser)
  const history = useHistory()
  const [setTitle, isTitleLoading] = useEditableTrainingProp('title', onChange)
  const [setLink, isLinkLoading] = useEditableTrainingProp(
    'customToken',
    onChange,
  )
  const [setEnabled] = useEditableTrainingProp('enabled', onChange)

  const [setLocked] = useEditableTrainingProp('locked', onChange)
  const [setDisableMultiuser, isMultiuserTogleLoading] =
    useEditableTrainingProp('disableMultiuser', onChange)
  const [setPassword, isPasswordLoading] = useEditableTrainingProp(
    'password',
    onChange,
  )
  const [setPasswordEnabled] = useEditableTrainingProp(
    'passwordEnabled',
    onChange,
  )
  const [setDescription, isDescriptionLoading] = useEditableTrainingProp(
    'description',
    onChange,
  )
  const {
    title,
    description,
    customToken,
    distributionToken: uniqueToken,
    disableMultiuser = false,
  } = training
  const [showModal, setShowModal] = useState('')
  const [duplicatedTrainingId, setDuplicatedTrainingId] = useState('')

  const toggleLocked = useCallback(() => {
    if (training.locked) setLocked(false)
    else setLocked(true)
    setShowModal('')
  }, [training.locked, setLocked])

  const [duplicateTraining, isDuplicateLoading] = useDuplicateTraining()
  const [deleteTraining, isDeleteLoading] = useDeleteTraining()
  console.log('!2 training', training)

  return (
    <>
      <LinkButton to="/" text="Return" icon="arrow-left" />
      <div className="details-intro details-intro-simple">
        <TrainingDetailsThumbnail
          src={training.thumbnail || DEFAULT_TRAINING_THUMBNAIL}
          isLoading={isLoading}
          web={true}
        />

        <div className="details-main">
          <div className="details-fields">
            <div className="details-fieldsCol">
              <TrainingDetailsField
                id="detail-title-input"
                label="Training:"
                onSave={setTitle}
                initialValue={title}
                isLoading={isTitleLoading}
                className="details-title"
                fieldClass="title-field"
              />

              <TrainingDetailsField
                id="detail-description-input"
                label="Description:"
                onSave={setDescription}
                initialValue={description}
                isLoading={isTitleLoading}
              />
            </div>
            <div className="details-fieldsCol">
              <TrainingDetailsFieldLink
                enabled={training.enabled}
                onSave={setLink}
                initialValue={customToken}
                link={customToken}
                isLoading={isLinkLoading}
                checkedSwitch={training.enabled}
                domain={env.redirectorV3URL}
                onChangeSwitch={ev => {
                  setEnabled(
                    (ev.target as HTMLInputElement).checked ? true : false,
                  )
                }}
              />

              <Switch
                large
                id="multiuser-enabled-switchbox"
                disabled={isMultiuserTogleLoading}
                checked={!training.disableMultiuser}
                className="details-switch"
                label="Enable multi-user sessions"
                onChange={ev =>
                  setDisableMultiuser(
                    (ev.target as HTMLInputElement).checked ? false : true,
                  )
                }
              />

              <div className="details-password">
                <Switch
                  id="password-enabled-switchbox"
                  large
                  disabled={isPasswordLoading}
                  checked={training.passwordEnabled}
                  className="details-switch"
                  label="Set password"
                  onChange={ev =>
                    setPasswordEnabled(
                      (ev.target as HTMLInputElement).checked ? true : false,
                    )
                  }
                />
                {training.passwordEnabled && (
                  <EditableTextElement
                    id="password-text-input"
                    onSave={setPassword}
                    initialValue={training.password}
                    isLoading={isPasswordLoading}
                    copyToClipboard={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="details-actions">
          <ActionButtonLink
            id="detail-edit-button"
            href={editorLink}
            icon="edit"
            text="Edit"
            selected={false}
            disabled={training.locked}
            className="editor-access"
          />
          <ActionButton
            id="detail-lock-button"
            text={training.locked ? 'Unlock' : 'Lock'}
            onClick={() => setShowModal('lock')}
            icon={training.locked ? 'lock' : 'unlock'}
            selected={false}
          />

          <ActionButton
            id="detail-duplicate-button"
            text="Duplicate"
            onClick={() => {
              const newId = Math.random().toString(36).substr(2, 9)
              setDuplicatedTrainingId(newId)
              duplicateTraining(training.id, newId)
              setShowModal('duplicate')
            }}
            icon="duplicate"
            selected={false}
          />
          <ActionButton
            text="Share"
            onClick={() => setShowModal('share')}
            icon="share"
            selected={showModal === 'share'}
          />
          {user && user.isAdmin && (
            <ActionButton
              id="detail-move-button"
              text="Move"
              onClick={() => setShowModal('move')}
              icon="hand-up"
              selected={false}
              disabled={training.locked}
            />
          )}
          <ActionButton
            id="detail-delete-button"
            text="Delete"
            onClick={() => setShowModal('delete')}
            icon="trash"
            selected={false}
            disabled={training.locked}
          />

          <DialogLockTraining
            isOpen={showModal === 'lock'}
            locked={training.locked}
            onConfirm={toggleLocked}
            onClose={() => setShowModal('')}
            isLoading={false}
          />
          <DialogDuplicateTraining
            isOpen={showModal === 'duplicate'}
            onConfirm={() => {
              setShowModal('')
              history.push(`/trainings/${duplicatedTrainingId}`)
            }}
            onClose={() => setShowModal('')}
            isLoading={isDuplicateLoading}
          />
          <DialogShareTraining
            isOpen={showModal === 'share'}
            onClose={() => setShowModal('')}
            isLoading={false}
            uniqueToken={uniqueToken}
            disableMultiuser={disableMultiuser}
            isV3={true}
            embedLink={embedLink}
          />
          <DialogMoveTraining
            trainingId={training.id}
            onConfirm={() => setShowModal('')}
            isOpen={showModal === 'move'}
            onClose={() => setShowModal('')}
          />
          <DialogDeleteTraining
            isOpen={showModal === 'delete'}
            onClose={() => setShowModal('')}
            isLoading={isDeleteLoading}
            onDelete={async () => {
              await deleteTraining(training.id)
              setShowModal('')
            }}
          />
        </div>
      </div>
    </>
  )
}

export default TrainingDetailsOverview
