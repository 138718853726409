import React from 'react'
import SpinnerOverlay from 'lib/ui-components/components/spinners/SpinnerOverlay'
import {
  FormGroup,
  InputGroup,
  Button,
  Classes,
  Intent,
} from '@blueprintjs/core'
import { Formik } from 'formik'
import validate from 'validate.js'
import { SettingsFormValues } from 'features/userSettings/types'

// -------------------------
// types

interface Props {
  onSubmit: (values: SettingsFormValues, extra: any) => any
  initialValues: SettingsFormValues
  isLoading: boolean
}

// -------------------------
// form validation

const nameConstrains = {
  format: {
    pattern: '^(?! )[^0-9]+',
    message: "can't be blank or contain numbers",
  },
}

const constraints = {
  postalCode: { format: /^\d+$/ },
  name: nameConstrains,
  family_name: nameConstrains,
  company: { presence: { allowEmpty: false } },
}

function validateForm(values: any): Error {
  return validate(values, constraints)
}

// -------------------------
// ui component

const PersonalInfoForm: React.FC<Props> = ({
  onSubmit,
  initialValues,
  isLoading,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        dirty,
        isValid,
      }: any) => (
        <form className="settings-form">
          <FormGroup
            label="Email"
            labelFor="email"
            className={(isLoading || isSubmitting) && Classes.SKELETON}
          >
            <InputGroup id="email" disabled value={values.email} />
          </FormGroup>
          <FormGroup
            label="Last Name"
            labelFor="family_name"
            className={(isLoading || isSubmitting) && Classes.SKELETON}
            intent={errors.family_name && Intent.DANGER}
            helperText={errors.family_name}
          >
            <InputGroup
              id="family_name"
              onChange={handleChange}
              intent={
                touched.family_name && errors.family_name && Intent.DANGER
              }
              value={values.family_name}
            />
          </FormGroup>
          <FormGroup
            label="First Name"
            labelFor="name"
            className={(isLoading || isSubmitting) && Classes.SKELETON}
            intent={errors.name && Intent.DANGER}
            helperText={errors.name}
          >
            <InputGroup
              id="name"
              onChange={handleChange}
              intent={touched.name && errors.name && Intent.DANGER}
              value={values.name}
            />
          </FormGroup>
          <FormGroup
            label="Company"
            labelFor="company"
            className={(isLoading || isSubmitting) && Classes.SKELETON}
            intent={errors.company && Intent.DANGER}
            helperText={errors.company}
          >
            <InputGroup
              id="company"
              onChange={handleChange}
              intent={touched.company && errors.company && Intent.DANGER}
              value={values.company}
            />
          </FormGroup>
          <div className="settings-actions">
            {isLoading || isSubmitting ? (
              <SpinnerOverlay />
            ) : (
              <Button
                icon="floppy-disk"
                intent="primary"
                onClick={handleSubmit}
                disabled={!dirty || !isValid}
              >
                Save
              </Button>
            )}
          </div>
        </form>
      )}
    </Formik>
  )
}

export default PersonalInfoForm
