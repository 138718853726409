import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Footer from 'lib/ui-components/components/footers/Footer'
import 'lib/ui-components/components/layouts/layouts.scss'

const MainLayout = ({ children, className, headerSlot }) => {
  return (
    <div className="outer-container">
      {headerSlot}
      <main className={cx('main-container', { [className]: className })}>
        <div className="wrapper">{children}</div>
        <Footer />
      </main>
    </div>
  )
}

MainLayout.propTypes = {
  headerSlot: PropTypes.node,
  children: PropTypes.node,
  activeButton: PropTypes.any,
  className: PropTypes.string,
}

export default MainLayout
