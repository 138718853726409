import React from 'react'
import { Switch } from '@blueprintjs/core'

interface Props {
  id: string
  checked: any
  onChange: (ev: any) => void
  label: string
}

const SwitchBox: React.FC<Props> = ({ checked, onChange, label, id }) => {
  return (
    <div id={id} className="switch-box">
      <Switch label={label} checked={checked} onChange={onChange} />
    </div>
  )
}

export default SwitchBox
