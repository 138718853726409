import React from 'react'
import AuthLayout from 'lib/ui-components/components/layouts/AuthLayout'
import { AnchorButton } from '@blueprintjs/core'

const EmailConfirmationPage: React.FC<{}> = () => (
  <AuthLayout
    project="Manager"
    title="Confirm your email"
    subtitle="Please check your email inbox and follow the instructions to confirm your account."
  >
    <AnchorButton href="/auth/login" icon="person" intent="primary" large fill>
      I have already confirmed my email
    </AnchorButton>
  </AuthLayout>
)

export default EmailConfirmationPage
