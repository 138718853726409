import React from 'react'
import PropTypes from 'prop-types'
import 'lib/stats-graphs/components/chart-boxes/chart-box.scss'

const ChartBoxGroup = ({ children }) => {
  return <div className="chartBox-group">{children}</div>
}

ChartBoxGroup.propTypes = {
  children: PropTypes.node,
}

export default ChartBoxGroup
