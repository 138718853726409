import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchData, getData } from 'features/stats/statsSlice'
import { encodeGraphData, isDataEmpty } from 'features/stats/utils/data'
import StatsPageView from 'features/stats/StatsPageView'
import { CustomerStats } from 'features/stats/types'
import { getTargetUserEmail } from 'api/credentials'

const useFetchData = (range: number) => {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      await dispatch(fetchData(range))
      setLoading(false)
    }
    fetch()
  }, [range, dispatch, setLoading])
  return isLoading
}

const rangeValues: { [key: string]: number } = {
  '1-day': 1,
  '1-week': 7,
  '1-month': 30,
  '3-months': 90,
  '6-months': 180,
  '1-year': 365,
}

const StatsPage = () => {
  const data = useSelector(getData) as CustomerStats
  const [email, setEmail] = useState('')
  const [range, setRange] = useState('1-week')

  useEffect(() => {
    const set = async () => setEmail(await getTargetUserEmail())
    set()
  }, [data])

  const onChangeRange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newRange = ev.target.value
    setRange(newRange)
  }

  const loading = useFetchData(rangeValues[range])

  const graphData = isDataEmpty(data) ? {} : encodeGraphData(data)

  return (
    <StatsPageView
      onChange={onChangeRange}
      customerEmail={email}
      data={graphData}
      isLoading={loading}
      range={range}
    />
  )
}

export default StatsPage
