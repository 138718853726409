import React from 'react'
import PropTypes from 'prop-types'
import { Card, H3 } from '@blueprintjs/core'
import HeaderContainer from 'lib/ui-components/components/headers/HeaderContainer'
import Footer from 'lib/ui-components/components/footers/Footer'
import 'lib/ui-components/components/layouts/layouts.scss'

const AuthLayout = ({ children, title, subtitle, project }) => (
  <div className="auth-container">
    <HeaderContainer project={project} />
    <main className="auth-content">
      <Card className="auth-card">
        <H3 className="auth-title">{title}</H3>
        {subtitle ? <h6 className="auth-subtitle">{subtitle}</h6> : null}
        {children}
      </Card>
    </main>
    <Footer />
  </div>
)

AuthLayout.propTypes = {
  project: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
}

export default AuthLayout
