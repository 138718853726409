import React, { useState } from 'react'
import MainLayoutContainer from 'ui/layouts/MainLayoutContainer'
import PersonalInfoForm from 'features/userSettings/components/PersonalInfoForm'
import SettingsCol from 'features/userSettings/components/SettingsCol'
import ManagementBlock from 'features/userSettings/components/ManagementBlock'
import DialogDeleteUser from 'ui/dialogs/DialogDeleteUser'
import { H2, Button, Intent } from '@blueprintjs/core'
import { SettingsFormValues } from './types'
import cx from 'classnames'
import { ReactComponent as IconActivateUser } from 'lib/ui-components/globals/assets/icons/icon-activate-user.svg'
import { ReactComponent as IconDeactivateUser } from 'lib/ui-components/globals/assets/icons/icon-deactivate-user.svg'

// -------------------------
// types

interface UserSettingsPageProps {
  onSubmit: (values: SettingsFormValues, extra?: any) => any
  onUserDelete: () => any
  onUserDisable: () => any
  initialValues: SettingsFormValues
  isLoading: boolean
  isDeleting: boolean
  isDeleted: boolean
  isEnabled: boolean
  userIsAdmin: boolean
  targetIsAdmin: boolean
}

// -------------------------
// ui component

const UserSettingsPageView: React.FC<UserSettingsPageProps> = ({
  onSubmit,
  initialValues,
  isLoading,
  isDeleting,
  isDeleted,
  isEnabled,
  onUserDelete,
  onUserDisable,
  userIsAdmin,
  targetIsAdmin,
}) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <MainLayoutContainer userSelected={true}>
      <div
        className={cx('settings-page wrapper', { 'admin-user': userIsAdmin })}
      >
        <div className="settings-intro">
          <H2 className="settings-title">Account settings</H2>
          {userIsAdmin && (
            <div className="settings-introActions">
              {!targetIsAdmin && (
                <Button
                  text={isEnabled ? 'Deactivate user' : 'Reactivate User'}
                  icon={
                    isEnabled ? <IconDeactivateUser /> : <IconActivateUser />
                  }
                  minimal
                  onClick={() => onUserDisable()}
                />
              )}
              <Button
                text="Delete user"
                icon="trash"
                intent={Intent.DANGER}
                minimal
                onClick={() => setShowModal(true)}
              />
            </div>
          )}
        </div>
        <div className="settings-content">
          <SettingsCol title="Personal information">
            <PersonalInfoForm
              onSubmit={onSubmit}
              initialValues={initialValues}
              isLoading={isLoading}
            />
          </SettingsCol>

          {userIsAdmin && (
            <SettingsCol title="Management" subtitle="Account Status">
              <ManagementBlock updateAttributes={onSubmit} />
            </SettingsCol>
          )}
        </div>
      </div>
      <DialogDeleteUser
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        isLoading={isDeleting}
        onDelete={onUserDelete}
        confirmation={isDeleted}
      />
    </MainLayoutContainer>
  )
}

export default UserSettingsPageView
