import React from 'react'
import MainLayoutContainer from 'ui/layouts/MainLayoutContainer'
import { TrainingStats, Training } from './types'
import cx from 'classnames'
import SpinnerOverlay from 'lib/ui-components/components/spinners/SpinnerOverlay'
import TrainingDetailsOverview from './TrainingDetailsOverview'
import DashboardComposition from 'lib/stats-graphs/components/compositions/DashboardComposition'
import TimeSelector from 'lib/ui-components/components/time-selector/TimeSelector'

export interface TrainingDetailsViewProps {
  trainingStats: TrainingStats | {}
  isStatsLoading: boolean
  isDetailsLoading: boolean
  onChangeRange: any
  selectedRange: string
  onChangeTraining: (...args: any[]) => any
  training: Training
}

const TrainingDetailsPageView: React.FC<TrainingDetailsViewProps> = ({
  trainingStats,
  isStatsLoading,
  isDetailsLoading,
  onChangeRange,
  selectedRange,
  onChangeTraining,
  training,
}) => {
  return (
    <MainLayoutContainer>
      {isDetailsLoading && <SpinnerOverlay opaque />}
      <div id="details-page" className={cx('details-page')}>
        <TrainingDetailsOverview
          key={`details-${training.id}`}
          isLoading={isDetailsLoading}
          onChange={onChangeTraining}
          training={training}
        />
        {!isEmpty(trainingStats) && (
          <div className={cx('details-page', 'container')}>
            {isStatsLoading && <SpinnerOverlay />}

            <TimeSelector
              onChange={onChangeRange}
              selectedValue={selectedRange}
            />
            <DashboardComposition
              range={selectedRange}
              data={trainingStats}
              isLoading={isStatsLoading}
            />
          </div>
        )}
      </div>
    </MainLayoutContainer>
  )
}

function isEmpty(data: any) {
  return Object.keys(data).length < 1
}

export default TrainingDetailsPageView
