import React, { useEffect } from 'react'
import MainNav from 'ui/navs/MainNav'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUserList, getUserList } from 'features/userAuth/userAuthSlice'
import { getCurrentUser } from 'features/userAuth/userAuthSlice'
import { User } from 'features/userAuth/types'
import useImpersonatedUser from 'hooks/useImpersonatedUser'
import { useHistory } from 'react-router'
import FreeTrialBanner from 'ui/banners/FreeTrialBanner'
import UserViewBanner from 'ui/banners/UserViewBanner'
import HeaderContainer from 'lib/ui-components/components/headers/HeaderContainer'

type Props = {
  activeButton: 'overview' | 'stats'
  userSelected?: boolean
}

const DOCUMENTATION_LINK =
  'https://auctaio.notion.site/auctaio/AUCTA-Docs-c3c80d2b83ea4f4b87ff8f2c1ced1369'

const useFetchUserList = (currentUser: User | null) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (currentUser && currentUser.isAdmin) {
      dispatch(fetchUserList())
    }
  }, [currentUser, dispatch])
}

const Header: React.FC<Props> = ({ activeButton, userSelected }) => {
  const history = useHistory()
  const user = useSelector(getCurrentUser)
  useFetchUserList(user)
  const userList = useSelector(getUserList)
  const [impersonatedUser, setImpersonatedUser] = useImpersonatedUser()

  const mainNavButtonHandler = (button: string) => {
    if (button === 'overview') history.push('/')
    if (button === 'stats') history.push('/stats')
  }

  return (
    <HeaderContainer
      project="Manager"
      topSlot={
        <>
          {!user?.paidAccount && !impersonatedUser && <FreeTrialBanner />}
          {impersonatedUser && (
            <UserViewBanner impersonatedUser={impersonatedUser} />
          )}
        </>
      }
    >
      <MainNav
        activeButton={activeButton}
        userSelected={userSelected}
        user={user}
        userList={userList}
        impersonatedUser={impersonatedUser}
        onUserSelect={setImpersonatedUser}
        navButtonHandler={mainNavButtonHandler}
        documentationLink={DOCUMENTATION_LINK}
      />
    </HeaderContainer>
  )
}

export default Header
