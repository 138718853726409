import { useSelector, useDispatch } from 'react-redux'
import {
  getImpersonatedUser,
  setImpersonatedUser,
  getCurrentUser,
} from 'features/userAuth/userAuthSlice'
import { useHistory } from 'react-router-dom'
import { User } from 'features/userAuth/types'

export const useImpersonatedUser = (): [
  User | null,
  (user: User | null) => void,
] => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(getImpersonatedUser) as User | null
  const currentUser = useSelector(getCurrentUser)

  const setUser = (user: User | null) => {
    if (user === null) {
      dispatch(setImpersonatedUser(null))
    } else if (currentUser && currentUser.sub === user.sub) {
      dispatch(setImpersonatedUser(null))
    } else {
      dispatch(setImpersonatedUser(user))
    }
    history.push('/')
  }
  return [user, setUser]
}

export default useImpersonatedUser
