import React from 'react'
import { calculateDaysLeft, formatCognitoDate, addMonth } from 'utils/time'
import { getCurrentUser } from 'features/userAuth/userAuthSlice'
import { useSelector } from 'react-redux'
import Banner from 'lib/ui-components/components/banners/Banner'

const FreeTrialBanner: React.FC = () => {
  const user = useSelector(getCurrentUser)
  const customEndOfTrial = user?.endOfTrial ? new Date(user.endOfTrial) : null
  const signUpDate = user?.signUpDate
  const expirationDate =
    customEndOfTrial || addMonth(formatCognitoDate(signUpDate))
  const month = expirationDate.toDateString().split(' ')[1]
  const day = expirationDate.getDate()
  const days = calculateDaysLeft(expirationDate)

  return (
    <Banner
      position="top"
      icon="info-sign"
      text={
        <>
          {days > 0
            ? `Your free trial expires in ${days} days, on ${month} ${day}th. `
            : `Your free trial has expired. `}
          <a href="mailto:contact@aucta.io" className="banner-link">
            Contact us{' '}
          </a>
          for the next steps.
        </>
      }
    />
  )
}

export default FreeTrialBanner
