import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import RegisterPage from 'features/userAuth/RegisterPage'
import EmailConfirmationPage from 'features/userAuth/EmailConfirmationPage'
import LoginPage from 'features/userAuth/LoginPage'
import LogoutPage from 'features/userAuth/LogoutPage'
import PasswordRecoveryPage from 'features/userAuth/PasswordRecoveryPage'
import PasswordResetPage from 'features/userAuth/PasswordResetPage'
import TrainingsListPage from 'features/trainingsList/TrainingsListPage'
import StatsPage from 'features/stats/StatsPage'
import TrainingDetailsPage from 'features/trainingDetails/TrainingDetailsPage'
import UserSettingsPage from 'features/userSettings/UserSettingsPage'
import NotFoundMessage from 'ui/messages/NotFoundMessage'

const App: React.FC<{}> = () => (
  <Router>
    <Switch>
      {/* auth */}
      <Route path="/auth/register" component={RegisterPage} />
      <Route
        path="/auth/email-confirmation"
        component={EmailConfirmationPage}
      />
      <Route path="/auth/login" component={LoginPage} />
      <Route path="/auth/password-recovery" component={PasswordRecoveryPage} />
      <Route path="/auth/password-reset" component={PasswordResetPage} />
      <Route path="/auth/logout" component={LogoutPage} />

      {/* trainings */}
      <Route path="/trainings/:id" component={TrainingDetailsPage} />
      <Route path="/trainings" component={TrainingsListPage} />

      <Route path="/stats" component={StatsPage} />

      {/* settings */}
      <Route path="/settings" component={UserSettingsPage} />

      {/*

        <Route path="/login" component={LoginPage} />
        <Route path="/email-confirmation" component={EmailConfirmationPage} />
        <Route path="/password-recovery" component={PasswordRecoveryPage} />

        */}

      <Route exact path="/" component={TrainingsListPage} />
      <Route component={NotFoundMessage} />
    </Switch>
  </Router>
)

export default App
