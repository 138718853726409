import React from 'react'
import { Button } from '@blueprintjs/core'
import DialogLayout from 'lib/ui-components/components/dialogs/DialogLayout'

type Props = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  isLoading: boolean
}

const DialogDuplicateTraining: React.FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
}) => {
  return (
    <DialogLayout
      isOpen={isOpen}
      onClose={onClose}
      icon="duplicate"
      title="Duplicate training session"
      isLoading={isLoading}
      body={
        <>
          <p>
            A copy of this training session has been added to your manager.
            Click on the button to go to the new training session's page or
            close this window to go back.
          </p>
        </>
      }
      actions={
        !isLoading && (
          <Button
            id="detail-duplicate-confirm-button"
            intent="primary"
            onClick={onConfirm}
          >
            Go to the new training file
          </Button>
        )
      }
    />
  )
}

export default DialogDuplicateTraining
