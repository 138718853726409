export function formatDuration(seconds: number) {
  let output = ''
  if (seconds < 1) return `${Math.floor(seconds * 1000)}ms`
  if (seconds >= 86400) output += `${Math.floor(seconds / 86400)}d `
  if (seconds >= 3600) output += `${Math.floor((seconds % 86400) / 3600)}h `

  if (seconds >= 86400) return output

  if (seconds >= 60) output += `${Math.floor((seconds % 3600) / 60)}m `

  if (seconds >= 3600) return output

  if (seconds % 60 > 0) output += `${seconds % 60}s `
  return output
}

export function calculateDaysLeft(toDate: Date) {
  const milis: number = toDate.getTime() - Date.now()
  const daysLeft: number = Math.ceil(milis / (1000 * 60 * 60 * 24))
  return daysLeft
}

export function formatCognitoDate(date?: string) {
  if (!date) return null
  const year = parseInt(date.substring(0, 4))
  const month = parseInt(date.substring(5, 7))
  const day = parseInt(date.substring(8, 10))
  const formattedDate = new Date(`${month}/${day}/${year}`)
  return formattedDate
}

export function addMonth(date: Date | null): Date {
  const newDate = date || new Date()
  newDate.setDate(newDate.getDate() + 30)
  return newDate
}
