import React from 'react'
import cx from 'classnames'

import EditableTextElement from 'ui/editable-elements/EditableTextElement'

interface Props {
  label: string
  id: string
  className?: string
  fieldClass?: string
  onSave: any
  initialValue: any
  isLoading: boolean
}

const TrainingDetailsField: React.FC<Props> = ({
  label,
  className,
  onSave,
  initialValue,
  isLoading,
  fieldClass,
  id,
}) => {
  return (
    <div className={cx('details-field', className)}>
      <p className="details-label">{label}</p>
      <EditableTextElement
        id={id}
        onSave={onSave}
        initialValue={initialValue}
        className={fieldClass}
        isLoading={isLoading}
        copyToClipboard={false}
      />
    </div>
  )
}

export default TrainingDetailsField
