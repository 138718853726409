import React, { useState, useCallback } from 'react'
import { Button, Checkbox } from '@blueprintjs/core'
import DialogLayout from 'lib/ui-components/components/dialogs/DialogLayout'
import UserSelect from 'lib/ui-components/components/selects/UserSelect'
import { Intent } from '@blueprintjs/core'
import { useSelector, useDispatch } from 'react-redux'
import { getCurrentUser, getUserList } from 'features/userAuth/userAuthSlice'
import { User } from 'features/userAuth/types'
import Toaster from 'features/toasterService/Toaster'
import { useHistory } from 'react-router-dom'
import {
  copyTraining,
  deleteTraining,
} from 'features/trainingsList/trainingsListSlice'

type Props = {
  trainingId: string
  isOpen: boolean
  onConfirm: () => void
  onClose: () => void
}

function useMoveTrainingCallback(): [
  (id: string, keepCopy: boolean, userId?: string) => any,
  boolean,
] {
  const [isCopying, setCopying] = useState<boolean>(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(getCurrentUser)
  return [
    useCallback(
      async (id: string, keepCopy: boolean, userId?: string) => {
        try {
          const newId = Math.random().toString(36).substr(2, 9)
          setCopying(true)
          console.log('!> dispatching copying', id, `${userId}-${newId}`)
          await dispatch(copyTraining(id, newId, userId || user?.sub))
          if (!keepCopy) {
            console.log('!> deleting original')
            await dispatch(deleteTraining(id))
          }
          setCopying(false)
          Toaster.show({
            icon: 'floppy-disk',
            intent: Intent.PRIMARY,
            message: 'Training moved.',
          })
          if (!keepCopy) history.push(`/`)
        } catch (e) {
          setCopying(false)
          Toaster.show({
            icon: 'error',
            intent: Intent.DANGER,
            message: `Error: ${e.message}`,
          })
        }
      },
      [dispatch, history, user],
    ),
    isCopying,
  ]
}

const DialogMoveTraining: React.FC<Props> = ({
  trainingId,
  isOpen,
  onConfirm,
  onClose,
}) => {
  const [target, setTarget] = useState<User | null>(null)
  const [keepCopy, setKeepCopy] = useState<boolean>(false)
  const [moveTraining, moveLoading] = useMoveTrainingCallback()
  const user = useSelector(getCurrentUser)
  // const onSelect = useFetchUserList(user)
  const userList = useSelector(getUserList)
  return (
    <DialogLayout
      isOpen={isOpen}
      onClose={onClose}
      icon="hand-up"
      title="Move training session"
      className="dialog-move"
      isLoading={moveLoading}
      body={
        <div className="dialog-moveBox">
          <p>Move training session to other user's account.</p>
          <div className="dialog-select">
            <p>Select user: </p>
            {user && user.isAdmin && userList && (
              <UserSelect
                id="detail-move-dialog-select"
                currentUser={target || user}
                users={userList}
                onSelect={setTarget}
              />
            )}
          </div>
          <Checkbox
            id="detail-move-dialog-checkbox"
            label="Keep a copy of this training session in the current user's account"
            onChange={ev =>
              setKeepCopy(
                (ev.target as HTMLInputElement).checked ? true : false,
              )
            }
          />
        </div>
      }
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            id="detail-move-button-confirm"
            onClick={() => {
              moveTraining(trainingId, keepCopy, target?.sub)
              onConfirm()
            }}
            intent="primary"
          >
            Move training session
          </Button>
        </>
      }
    />
  )
}

export default DialogMoveTraining
