import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import logo from 'lib/ui-components/globals/assets/images/aucta-logo.svg'
import logoSm from 'lib/ui-components/globals/assets/images/aucta-icon.svg'
import 'lib/ui-components/components/headers/header.scss'

const HeaderLogos = ({ project }) => {
  return (
    <>
      <Link to="/" id="header-logo" className="header-logo">
        <img src={logoSm} alt="Aucta" className="header-logoSm" />
        <img src={logo} alt="Aucta" className="header-logoXl" />
      </Link>
      <p className="header-project">{project}</p>
    </>
  )
}

HeaderLogos.propTypes = {
  project: PropTypes.string,
}

export default HeaderLogos
