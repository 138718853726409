import React from 'react'
import { H2 } from '@blueprintjs/core'

type Props = {
  title: string
  url: string
  width: string
  height: string
}

const VideoWrapper: React.FC<Props> = ({ title, url, width, height }) => {
  return (
    <div className="video-wrapper">
      <H2 className="video-title">{title}</H2>
      <div className="video-content">
        <iframe
          width={width}
          height={height}
          src={url}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
    </div>
  )
}

export default VideoWrapper
