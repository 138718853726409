import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getTrainings,
  fetchTrainings,
  createTraining,
  copyTraining,
  deleteTraining,
} from './trainingsListSlice'
import { Intent } from '@blueprintjs/core'
import TrainingsListPageView from './TrainingsListPageView'
import Toaster from 'features/toasterService/Toaster'
import { useHistory } from 'react-router-dom'
import { TrainingItem } from 'features/trainingsList/types'
import { getImpersonatedUser } from 'features/userAuth/userAuthSlice'

// -------------------------
// connection logic: queries

function useFetchTrainingsEffect(): boolean {
  const dispatch = useDispatch()
  const impersonatedUser = useSelector(getImpersonatedUser)
  const [isLoading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      await dispatch(fetchTrainings())
      setLoading(false)
    }
    fetch()
    const id = setTimeout(() => {
      dispatch(fetchTrainings())
    }, 5000)
    return () => {
      clearTimeout(id)
    }
  }, [dispatch, setLoading, impersonatedUser])
  return isLoading
}

function useDeleteTrainingCallback() {
  const dispatch = useDispatch()
  return useCallback(
    async (id: string) => {
      try {
        await dispatch(deleteTraining(id))
        Toaster.show({
          icon: 'floppy-disk',
          intent: Intent.PRIMARY,
          message: 'Training deleted.',
        })
      } catch (e) {
        Toaster.show({
          icon: 'error',
          intent: Intent.DANGER,
          message: `Error: ${e.message}`,
        })
      }
    },
    [dispatch],
  )
}

function useCreateTrainingCallback(): [() => any, boolean] {
  const [isCreating, setCreating] = useState<boolean>(false)
  const dispatch = useDispatch()
  const history = useHistory()
  return [
    useCallback(
      async (v3 = false) => {
        try {
          const trainingId = Math.random().toString(36).substr(2, 9)
          setCreating(true)
          await dispatch(createTraining(trainingId, v3))
          setCreating(false)
          Toaster.show({
            icon: 'floppy-disk',
            intent: Intent.PRIMARY,
            message: 'Training created.',
          })
          history.push(`/trainings/${trainingId}`)
        } catch (e) {
          Toaster.show({
            icon: 'error',
            intent: Intent.DANGER,
            message: `Error: ${e.message}`,
          })
        }
      },
      [dispatch, history],
    ),
    isCreating,
  ]
}

function useCopyTrainingCallback(): [(id: string) => any, boolean] {
  const [isCopying, setCopying] = useState<boolean>(false)
  const dispatch = useDispatch()
  const history = useHistory()
  return [
    useCallback(
      async (id: string) => {
        try {
          const newId = Math.random().toString(36).substr(2, 9)
          setCopying(true)
          console.log('!> dispatching copying', id, newId)
          await dispatch(copyTraining(id, newId))
          setCopying(false)
          Toaster.show({
            icon: 'floppy-disk',
            intent: Intent.PRIMARY,
            message: 'Training copied.',
          })
          history.push(`/trainings/${newId}`)
        } catch (e) {
          Toaster.show({
            icon: 'error',
            intent: Intent.DANGER,
            message: `Error: ${e.message}`,
          })
        }
      },
      [dispatch, history],
    ),
    isCopying,
  ]
}

const sorters: { [key: string]: (a: any, b: any) => number } = {
  newer_first: (a: any, b: any) => (b.updatedAt || 0) - (a.updatedAt || 0),
  older_first: (a: any, b: any) => (a.updatedAt || 0) - (b.updatedAt || 0),
  name_ascending: (a: any, b: any) => (a.title > b.title ? 1 : -1),
  name_descending: (a: any, b: any) => (a.title > b.title ? -1 : 1),
}

function useSort(): [
  (trainings: TrainingItem[]) => TrainingItem[],
  (sortingPreference: string) => void,
] {
  const [sortingPreference, setSortingPreference] = useState('newer_first')
  return [
    useCallback(
      trainings => {
        const clone = [...trainings]
        clone.sort(sorters[sortingPreference])
        return clone
      },
      [sortingPreference],
    ),
    (sortingPreference: string) => setSortingPreference(sortingPreference),
  ]
}

const TrainingsListPage: React.FC<{}> = () => {
  const trainings = useSelector(getTrainings)
  const isLoading = useFetchTrainingsEffect()
  const onDelete = useDeleteTrainingCallback()
  const sortingOptions = Object.keys(sorters).map(key => {
    return { value: key, text: key.replace('_', ' ') }
  })
  const [sort, setSortingPreference] = useSort()
  const [onCreate, isCreatingTraining] = useCreateTrainingCallback()
  const [onCopy, isCopyingTraining] = useCopyTrainingCallback()

  return (
    <TrainingsListPageView
      isLoading={isLoading}
      trainings={sort(trainings)}
      onDelete={onDelete}
      onCreate={onCreate}
      onCopy={onCopy}
      onSortChange={setSortingPreference}
      isCreatingTraining={isCreatingTraining}
      isCopyingTraining={isCopyingTraining}
      sortingOptions={sortingOptions}
    />
  )
}

export default TrainingsListPage
