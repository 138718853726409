import React from 'react'
import { Button, InputGroup, Intent, FormGroup, Icon } from '@blueprintjs/core'
import DialogLayout from 'lib/ui-components/components/dialogs/DialogLayout'
import ConfirmationMessage from 'ui/messages/ConfirmationMessage'

type Props = {
  isOpen: any
  onClose: () => void
  onDelete: (...args: [any]) => void
  onEmailChange: (...args: [any]) => any
  isLoading: boolean
  hasError?: boolean
  confirmation?: boolean
}

const DiaglogDeleteUserView: React.FC<Props> = ({
  isOpen,
  onClose,
  onDelete,
  isLoading,
  hasError,
  onEmailChange,
  confirmation,
}) => {
  return (
    <DialogLayout
      isOpen={isOpen}
      onClose={onClose}
      icon="warning-sign"
      title="Delete user"
      status="danger"
      isLoading={isLoading}
      loadingText="Deleting user account..."
      spinnerIntent="danger"
      body={
        confirmation ? (
          <ConfirmationMessage
            icon="tick"
            text="User account has been deleted successfully"
            status="success"
          />
        ) : (
          <>
            <p>
              Are you sure you want to delete this user? This action is
              irreversible.
            </p>
            <p>Type in the user's email to confirm this action:</p>
            <FormGroup>
              <InputGroup
                id="user_email"
                onChange={(ev: any) => onEmailChange(ev.target.value)}
                placeholder="Email"
                large
                intent={hasError ? Intent.DANGER : Intent.SUCCESS}
                rightElement={
                  hasError ? <Icon icon="warning-sign" /> : <Icon icon="tick" />
                }
              />
            </FormGroup>
          </>
        )
      }
      actions={
        confirmation || isLoading ? (
          <Button intent="primary" onClick={onClose}>
            Close
          </Button>
        ) : (
          <>
            <Button onClick={onClose}>Close</Button>
            <Button
              id="detail-delete-confirm-button"
              onClick={onDelete}
              intent="danger"
              icon="trash"
              disabled={hasError}
            >
              Delete user
            </Button>
          </>
        )
      }
    />
  )
}

export default DiaglogDeleteUserView
