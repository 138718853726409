import React from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, MenuItem, Spinner } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

import 'lib/ui-components/components/banners/banner.scss'

const DropdownButton = ({
  buttonIcon,
  loading,
  intent,
  buttonText,
  onClick,
  items,
}) => {
  return (
    <div className="button-dropdown">
      <Button
        className="button-main"
        icon={buttonIcon}
        loading={loading}
        text={buttonText}
        intent={intent}
        onClick={onClick}
      />
      <Popover2
        placement="bottom-end"
        content={
          <Menu>
            {items.map(item => (
              <MenuItem
                key={item.text}
                icon={item.icon}
                text={item.text}
                onClick={item.onClick}
              >
                {item.loading ? <Spinner intent="primary" /> : null}
              </MenuItem>
            ))}
          </Menu>
        }
      >
        <Button className="button-icon" icon="chevron-down" intent={intent} />
      </Popover2>
    </div>
  )
}

DropdownButton.propTypes = {
  buttonIcon: PropTypes.any,
  loading: PropTypes.bool,
  intent: PropTypes.oneOf(['none', 'primary', 'success', 'warning', 'danger']),
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.any,
      onClick: PropTypes.func,
    }),
  ),
}

export default DropdownButton
