import React, { useState, useEffect } from 'react'
import { getTargetUserEmail } from 'api/credentials'
import DiaglogDeleteUserView from './DialogDeleteUserView'

type Props = {
  isOpen: any
  onClose: () => void
  onDelete: (...args: [any]) => void
  isLoading: boolean
  confirmation?: boolean
}

const DialogDeleteUser: React.FC<Props> = ({
  confirmation,
  isOpen,
  onClose,
  onDelete,
  isLoading,
}) => {
  const [emailInput, setEmailInput] = useState(undefined)
  const [error, setError] = useState(true)

  useEffect(() => {
    const check = async () => {
      const email = await getTargetUserEmail()
      if (email === emailInput) setError(false)
      else setError(true)
    }
    check()
  }, [emailInput])

  return (
    <DiaglogDeleteUserView
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      onDelete={onDelete}
      hasError={error}
      confirmation={confirmation}
      onEmailChange={setEmailInput}
    />
  )
}

export default DialogDeleteUser
