import React, { useState } from 'react'
import cx from 'classnames'
import { Classes } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import TrainingsListAction from 'features/trainingsList/components/TrainingsListAction'
import TrainingsListActionLink from 'features/trainingsList/components/TrainingsListActionLink'
import TrainingsListLinkSize from 'features/trainingsList/components/TrainingsListLinkSize'
import DialogDeleteTraining from 'ui/dialogs/DialogDeleteTraining'
import { DEFAULT_TRAINING_THUMBNAIL } from 'config/constants'

// utils

function formatDate(d: number): string {
  return new Date(d).toISOString().slice(0, 10)
}

const TrainingsListItemView: React.FC<any> = ({
  isLoading,
  onDeleteItem,
  onCopy,
  training,
  isLockLoading,
  isItemLocked,
  onLockClick,
  link,
  editorLink,
}) => {
  const [showModal, setShowModal] = useState(false)
  const isV3 = training.version === 'v3'

  return (
    <div
      className={cx('training-item', {
        [Classes.SKELETON]: isLoading,
      })}
    >
      <Link to={`/trainings/${training.id}`} className="training-itemLink">
        <div className="training-itemContent">
          <div className="training-itemThumbnail">
            <img
              src={training.thumbnail || DEFAULT_TRAINING_THUMBNAIL}
              alt="training thumbnail"
            />
          </div>
          <div className="training-itemTitle">
            <span className="title">{training.title}</span>
            {isV3 && <span className="web">web</span>}
          </div>

          <div className="training-itemCell">
            {!training.updatedAt || training.updatedAt === 0
              ? '---'
              : formatDate(training.updatedAt)}
          </div>
          <div className="training-itemCell">{training.totalSteps}</div>
          <div className="training-itemCell">{training.stats?.sessions}</div>
          <TrainingsListLinkSize status="success" text="---" />
          <div className="training-itemCell">
            {training.enabled ? 'public' : 'private'}
          </div>
        </div>
      </Link>
      <div className="training-itemActions">
        <TrainingsListActionLink
          href={link}
          disabled={!training.enabled || isLockLoading}
          icon="link"
          loading={false}
          title="Link to training"
        />
        <TrainingsListActionLink
          href={editorLink}
          extraClass="edit"
          disabled={isItemLocked || isLockLoading}
          icon="edit"
          loading={false}
          title="Edit training"
          className="editor-access"
        />
        <TrainingsListAction
          onclick={onLockClick}
          extraClass="lock"
          disabled={isLockLoading}
          icon={isItemLocked ? 'lock' : 'unlock'}
          title={isItemLocked ? 'Unlock training' : 'Lock training'}
          loading={isLockLoading}
        />
        <TrainingsListAction
          onclick={() => onCopy(training.id)}
          extraClass="duplicate"
          disabled={isLockLoading}
          icon="duplicate"
          loading={false}
          title="Duplicate training"
        />
        <TrainingsListAction
          onclick={() => setShowModal(true)}
          extraClass="delete"
          disabled={!!isItemLocked || isLockLoading}
          icon="trash"
          loading={false}
          title="Delete training"
        />
      </div>
      <DialogDeleteTraining
        isLoading={isLoading}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onDelete={onDeleteItem}
      />
    </div>
  )
}

export default TrainingsListItemView
