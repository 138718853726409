import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@blueprintjs/core'
import cx from 'classnames'
import 'lib/ui-components/components/buttons/action-button.scss'

const ActionButton = ({
  id,
  icon,
  text,
  selected,
  onClick,
  disabled,
  className,
}) => {
  return (
    <Button
      id={id}
      className={cx('action-button', {
        'is-selected': selected,
        'is-disabled': disabled,
        [className]: className,
      })}
      onClick={onClick}
      icon={icon}
    >
      {text}
    </Button>
  )
}

ActionButton.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.any,
  text: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.any,
}

export default ActionButton
