import React from 'react'
import { Switch, Button, Intent } from '@blueprintjs/core'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { User, EditableCognitoAttributes } from 'features/userAuth/types'
import { useSelector } from 'react-redux'
import { getTargetUser } from 'features/userAuth/userAuthSlice'
import { getExpirationDate } from './utils'
import { calculateDaysLeft } from 'utils/time'

interface ManagementBlockProps {
  updateAttributes: (attrs: EditableCognitoAttributes) => any
}

const ManagementBlock: React.FC<ManagementBlockProps> = ({
  updateAttributes,
}) => {
  const user = useSelector(getTargetUser) as User
  const isFreeTrialActive = !user.paidAccount
  const date = getExpirationDate(user)
  const daysLeft = calculateDaysLeft(date)

  const handleFreeTrialToggle = () => {
    updateAttributes({
      paidAccount: !user.paidAccount,
    })
  }

  const handleFreeTrialDateChange = (date: Date) => {
    updateAttributes({
      endOfTrial: date.toString(),
    })
  }

  return (
    <div className="settings-management">
      <Switch
        large
        label="Free trial"
        onChange={handleFreeTrialToggle}
        checked={isFreeTrialActive}
      />
      {isFreeTrialActive ? (
        <div className="settings-datepicker">
          <p className="settings-dateInfo">
            Ends on {date.toLocaleDateString()} ({daysLeft} days left)
          </p>
          <DatePicker
            selected={date}
            onChange={handleFreeTrialDateChange}
            customInput={
              <Button
                intent={Intent.PRIMARY}
                text="Extend free trial"
                icon="calendar"
                minimal
              />
            }
          />
        </div>
      ) : null}
    </div>
  )
}

export default ManagementBlock
