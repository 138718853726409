import React from 'react'
import { NonIdealState } from '@blueprintjs/core'
import { TrainingItem } from 'features/trainingsList/types'
import TrainingsListItem from 'features/trainingsList/components/TrainingsListItem'

type Props = {
  trainings: TrainingItem[]
  onDelete: (id: string) => Promise<void>
  onCopy: (id: string) => Promise<void>
}

const TrainingsList: React.FC<Props> = ({ trainings, onDelete, onCopy }) => {
  return (
    <div className="training-list">
      <div className="training-listHeader">
        <div className="training-cellTitle">
          <span>Name</span>
        </div>
        <div className="training-cell">
          <span>Last Updated</span>
        </div>
        <div className="training-cell">
          <span>Steps</span>
        </div>
        <div className="training-cell">
          <span>Total sessions</span>
        </div>
        <div className="training-cell">
          <span>Link Size</span>
        </div>
        <div className="training-cell">
          <span>Distribution</span>
        </div>
      </div>
      <div className="training-listContent">
        {trainings.length < 1 && (
          <>
            <NonIdealState
              className="empty-state"
              icon="document"
              title="There are no trainings"
              description="To create one, click on the following button"
            />
          </>
        )}
        {trainings.map((training: TrainingItem) => {
          return (
            <TrainingsListItem
              key={training.id}
              training={training}
              onDelete={onDelete}
              onCopy={onCopy}
              isLocked={!!training.locked}
            />
          )
        })}
      </div>
    </div>
  )
}

export default TrainingsList
