import React from 'react'
import { Intent } from '@blueprintjs/core'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Toaster from 'features/toasterService/Toaster'
import PasswordResetPageView, {
  PasswordResetFormValues,
} from 'features/userAuth/PasswordResetPageView'

import { resetPasswordAction } from 'features/userAuth/userAuthSlice'

// -------------------------
// form logic

const initialValues: PasswordResetFormValues = {
  email: '',
  code: '',
  password: '',
  'confirm-password': '',
}

function useHandleSubmit() {
  const dispatch = useDispatch()
  const history = useHistory()
  return async (
    values: PasswordResetFormValues,
    { setSubmitting }: { setSubmitting: (arg: boolean) => void },
  ) => {
    setSubmitting(true)
    try {
      await dispatch(
        resetPasswordAction(values.email, values.code, values.password),
      )
      Toaster.show({
        icon: 'key',
        intent: Intent.PRIMARY,
        message: 'Password succesufully changed.',
      })
      history.push('/auth/login')
    } catch (e) {
      Toaster.show({
        icon: 'error',
        intent: Intent.DANGER,
        message: e.message,
      })
      setSubmitting(false)
    }
  }
}

// -------------------------
// ui component

const PasswordResetPage: React.FC<{}> = () => {
  const handleSubmit = useHandleSubmit()
  return (
    <PasswordResetPageView
      initialValues={initialValues}
      onSubmit={handleSubmit}
    />
  )
}

export default PasswordResetPage
