import { combineReducers } from '@reduxjs/toolkit'
import stats from 'features/stats/statsSlice'
import userAuth from 'features/userAuth/userAuthSlice'
import trainingsList from 'features/trainingsList/trainingsListSlice'
import trainingDetails from 'features/trainingDetails/trainingDetailsSlice'

const rootReducer = combineReducers({
  stats: stats.reducer,
  userAuth: userAuth.reducer,
  trainingsList: trainingsList.reducer,
  trainingDetails: trainingDetails.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
