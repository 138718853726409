import React from 'react'
import PropTypes from 'prop-types'
import ChartBox from 'lib/stats-graphs/components/chart-boxes/ChartBox'
import ChartBoxGroup from 'lib/stats-graphs/components/chart-boxes/ChartBoxGroup'
import CompositionChartBox from 'lib/stats-graphs/components/chart-boxes/CompositionChartBox'
import PieGraph from 'lib/stats-graphs/components/graphs/pieGraph'
import LineGraph from 'lib/stats-graphs/components/graphs/lineGraph'
import StackedAreaGraph from 'lib/stats-graphs/components/graphs/stackedAreaGraph'
import DataPanel from 'lib/stats-graphs/components/panels/DataPanel'
import { formatDuration } from 'lib/stats-graphs/utils/time'
import 'lib/stats-graphs/components/panels/data-panel.scss'

const DashboardComposition = ({ data, range, isLoading }) => {
  return (
    <>
      {!isEmpty(data) && (
        <div className="dataPanel-group">
          <DataPanel
            isLoading={isLoading}
            label="Average session duration"
            value={formatDuration(data.averageSessionTime.total)}
          />
          <DataPanel
            isLoading={isLoading}
            label="Group sessions"
            value={data.groupSessions.total}
          />
          <DataPanel
            isLoading={isLoading}
            label="Average group session duration"
            value={formatDuration(data.averageGroupSessionTime.total)}
          />
          <DataPanel
            isLoading={isLoading}
            label="Time spent in VR"
            value={formatDuration(data.VRTime.total)}
          />
          <DataPanel
            isLoading={isLoading}
            label="Average load time"
            value={formatDuration(data.averageLoadTime.total / 1000)}
          />
        </div>
      )}
      {!isEmpty(data) && (
        <ChartBoxGroup>
          {/* Ammounts ===================*/}
          <ChartBox
            title="User sessions"
            value={data.sessions.total}
            isLoading={isLoading}
          >
            <LineGraph
              history={data.sessions.history}
              labelName="User sessions"
              disableHistory={range === '1-day'}
            />
          </ChartBox>

          <ChartBox
            title="Total session time"
            value={formatDuration(data.sessionTime.total)}
            isLoading={isLoading}
          >
            <LineGraph
              history={data.sessionTime.history}
              labelName="Total session time"
              disableHistory={range === '1-day'}
            />
          </ChartBox>
          {/* Compositions ===================*/}
          <CompositionChartBox
            isLoading={isLoading}
            title="Browser composition"
            totalSlot={<PieGraph data={data.browsers.total} />}
            historicSlot={
              <StackedAreaGraph
                history={data.browsers.history}
                disableHistory={range === '1-day'}
              />
            }
          />
          <CompositionChartBox
            isLoading={isLoading}
            title="Device composition"
            totalSlot={<PieGraph data={data.devices.total} />}
            historicSlot={
              <StackedAreaGraph
                history={data.devices.history}
                disableHistory={range === '1-day'}
              />
            }
          />
        </ChartBoxGroup>
      )}
    </>
  )
}

function isEmpty(data) {
  return Object.keys(data).length < 1
}

DashboardComposition.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
}

export default DashboardComposition
