import React from 'react'
import cx from 'classnames'
import { Classes } from '@blueprintjs/core'
import { Tag } from '@blueprintjs/core'

interface Props {
  isLoading: boolean
  src: string
  web?: boolean
}

const TrainingDetailsThumbnail: React.FC<Props> = ({ isLoading, src, web }) => {
  return (
    <div
      className={cx('details-thumbnail', {
        [Classes.SKELETON]: isLoading,
      })}
    >
      <img src={src} alt="training thumbnail" />
      {web ? <Tag intent="primary">Web</Tag> : null}
    </div>
  )
}

export default TrainingDetailsThumbnail
