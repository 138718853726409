import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'
import { RootState } from 'app/rootReducer'
import { EditableTrainingProps, Training, TrainingStats } from './types'
import * as api from 'api/manager'
import { retrieveTrainingStats } from 'lib/aucta-backend/stats/api'
import { getTargetUserName } from 'api/credentials'

interface TrainingDetailsState {
  training: Training
  stats: TrainingStats | {}
}

const initialState: TrainingDetailsState = {
  training: {
    id: '',
    title: '',
    description: '',
    totalSteps: 0,
    thumbnail: '',
    updatedAt: 0,
    totalSessions: 0,
    linkSize: '',
    distributionToken: '',
    customToken: '',
    enabled: true,
    locked: false,
    password: '',
    passwordEnabled: false,
    disableMultiuser: false,
  },
  stats: {},
}

const trainingDetails = createSlice({
  name: 'trainingDetails',
  initialState,
  reducers: {
    resetTrainingDetails(state) {
      state.training = { ...initialState.training }
    },
    setTrainingDetails(state, action: PayloadAction<Training>) {
      state.training = { ...initialState, ...action.payload }
    },
    updateTrainingDetails(state, action: PayloadAction<Training>) {
      state.training = { ...state.training, ...action.payload }
    },
    setTrainingStats(state, action: PayloadAction<TrainingStats>) {
      state.stats = action.payload
    },
  },
})

export default trainingDetails

// -------------------------
// selectors

export const getTraining = (state: RootState): Training =>
  state.trainingDetails.training

export const getTrainingStats = (state: RootState): TrainingStats =>
  state.trainingDetails.stats as TrainingStats

// -------------------------
// async actions

export const fetchTraining =
  (id: string): AppThunk =>
  async dispatch => {
    const training = await api.getTraining(id)
    dispatch(trainingDetails.actions.setTrainingDetails(training))
  }

export const fetchTrainingStats =
  (id: string, range: number): AppThunk =>
  async dispatch => {
    const customer = await getTargetUserName()
    const data = await retrieveTrainingStats(customer, id, range)
    dispatch(trainingDetails.actions.setTrainingStats(data))
  }

export const resetTraining = (): AppThunk => async dispatch => {
  dispatch(trainingDetails.actions.resetTrainingDetails())
}

export const updateTraining =
  (id: string, props: EditableTrainingProps): AppThunk =>
  async dispatch => {
    const training: Training = await api.updateTraining(id, props)
    dispatch(trainingDetails.actions.updateTrainingDetails(training))
  }
