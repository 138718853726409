import React from 'react'
import MainLayoutContainer from 'ui/layouts/MainLayoutContainer'
import SpinnerOverlay from 'lib/ui-components/components/spinners/SpinnerOverlay'
import TimeSelector from 'lib/ui-components/components/time-selector/TimeSelector'
import DashboardComposition from 'lib/stats-graphs/components/compositions/DashboardComposition'
import cx from 'classnames'
import PageHeading from 'lib/ui-components/components/headings/PageHeading'

type Props = {
  onChange: any
  customerEmail: string
  range: any
  isLoading: boolean
  selectedValue?: string | number
  data: any
}

const StatsPageView: React.FC<Props> = ({
  onChange,
  customerEmail,
  range,
  isLoading,
  data,
}) => {
  const title = `Statistics for ${customerEmail}`
  return (
    <MainLayoutContainer activeButton="stats">
      <PageHeading title={title} />
      {isLoading && <SpinnerOverlay />}
      <div className={cx('details-page', 'container')}>
        <TimeSelector onChange={onChange} selectedValue={range} />
        <DashboardComposition range={range} data={data} isLoading={isLoading} />
      </div>
    </MainLayoutContainer>
  )
}

export default StatsPageView
