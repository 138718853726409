import React from 'react'
import cx from 'classnames'

type Props = {
  status: 'success' | 'warning' | 'danger'
  text: string
}

const TrainingsListLinkSize: React.FC<Props> = ({ status, text }) => {
  return (
    <div className={cx('training-itemCell', { [status]: status })}>{text}</div>
  )
}

export default TrainingsListLinkSize
