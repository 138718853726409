import { REDIRECTOR_URL_V3, REDIRECTOR_URL } from 'config/constants'
import { Training } from 'features/trainingDetails/types'
import { isV3 } from 'utils/v3'
import { TrainingItem } from 'features/trainingsList/types'

const usePlayerLink = (training: Training | TrainingItem) => {
  let playerURL = isV3(training) ? REDIRECTOR_URL_V3 : REDIRECTOR_URL
  let link = `${playerURL}/${training.customToken}`
  return link
}

export default usePlayerLink
