import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@blueprintjs/core'
import cx from 'classnames'

const ActivatedButton = ({
  id,
  className,
  onClick,
  isActivated,
  isLoading,
}) => {
  return (
    <Button
      id={id}
      className={cx(className, 'button-icon', {
        activated: isActivated,
      })}
      icon={isActivated ? 'tick' : 'floppy-disk'}
      onClick={onClick}
      loading={isLoading}
    />
  )
}

ActivatedButton.propTypes = {
  id: PropTypes.any,
  className: PropTypes.oneOf([PropTypes.string, PropTypes.bool]),
  onClick: PropTypes.func,
  text: PropTypes.string,
  isActivated: PropTypes.any,
  isLoading: PropTypes.bool,
}

export default ActivatedButton
