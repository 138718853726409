import Chart from 'react-apexcharts'
import React from 'react'
import { compositionGraphColors } from 'lib/stats-graphs/utils/colors'
import { NonIdealState } from '@blueprintjs/core'

const PieGraph = props => {
  const series = props.data ? Object.values(props.data) : []
  const labels = props.data ? Object.keys(props.data) : []

  const options = {
    chart: {
      width: 380,
      type: 'donut',
    },
    labels: labels,
    colors: compositionGraphColors,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    dataLabels: {
      style: {
        fontSize: '12px',
        fontFamily: 'Inter, Arial, sans-serif',
        fontWeight: 'bold',
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        color: '#000',
        opacity: 0.3,
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
        fontFamily: 'Inter, Arial, sans-serif',
      },
    },
    legend: {
      position: 'right',
      horizontalAlign: 'center',
      offsetY: -16,
      offsetX: 0,
      height: 230,
      fontSize: '14px',
      fontFamily: 'Inter, Arial',
      fontWeight: 400,
      formatter: undefined,
      labels: {
        colors: '#182026',
      },
      itemMargin: {
        horizontal: 4,
        vertical: 4,
      },
      markers: {
        width: 9,
        height: 9,
      },
    },
  }

  const chart =
    series.length > 0 ? (
      <Chart options={options} series={series} type="donut" height={300} />
    ) : (
      <NonIdealState
        icon="inbox-search"
        title="There is no data"
        description="No data available in the selected time range"
      />
    )

  return <div id="chart">{chart}</div>
}

export default PieGraph
