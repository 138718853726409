import React from 'react'
import { Icon } from '@blueprintjs/core'
import cx from 'classnames'

type Props = {
  status?: 'error' | 'success' | 'primary'
  icon: any
  text: string
}

const ConfirmationMessage: React.FC<Props> = ({ status, icon, text }) => {
  return (
    <div
      className={cx('message-confirmation', {
        [`status-${status}`]: status,
      })}
    >
      <Icon icon={icon} className="message-icon" />
      <p className="message-text">{text}</p>
    </div>
  )
}

export default ConfirmationMessage
