import React from 'react'
import AuthLayout from 'lib/ui-components/components/layouts/AuthLayout'
import {
  Intent,
  Classes,
  Spinner,
  FormGroup,
  InputGroup,
  Button,
} from '@blueprintjs/core'
import { Formik } from 'formik'
import validate from 'validate.js'

// -------------------------
// types

export interface PasswordResetFormValues {
  email: string
  code: string
  password: string
  'confirm-password': string
}

interface PasswordResetPageViewProps {
  initialValues: PasswordResetFormValues
  onSubmit: (values: PasswordResetFormValues, _: any) => void
}

// -------------------------
// form validation

const constraints = {
  email: { presence: { allowEmpty: false }, email: true },
  code: { format: /^\d{6}$/ },
  password: { presence: true, length: { minimum: 6, maximum: 10 } },
  'confirm-password': { equality: 'password' },
}

function validateForm(values: PasswordResetFormValues) {
  return validate(values, constraints)
}

// -------------------------
// ui component

const PasswordResetPageView: React.FC<PasswordResetPageViewProps> = ({
  initialValues,
  onSubmit,
}) => (
  <AuthLayout
    project="Manager"
    title="Reset password"
    subtitle="Use the code you received by email."
  >
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
      }: any) => (
        <form>
          <FormGroup
            label="Email Address"
            labelInfo="*"
            labelFor="email"
            className={isSubmitting && Classes.SKELETON}
            intent={touched.email && errors.email && Intent.DANGER}
            helperText={touched.email && errors.email && errors.email[0]}
          >
            <InputGroup
              large
              id="email"
              placeholder="john.smith@acme.com"
              type="email"
              value={values.email}
              onChange={handleChange}
              intent={touched.email && errors.email && Intent.DANGER}
            />
          </FormGroup>
          <FormGroup
            label="Verification Code"
            labelInfo="*"
            labelFor="code"
            className={isSubmitting && Classes.SKELETON}
            intent={touched.code && errors.code && Intent.DANGER}
            helperText={touched.code && errors.code}
          >
            <InputGroup
              large
              id="code"
              placeholder="000000"
              type="text"
              value={values.code}
              onChange={handleChange}
              intent={touched.code && errors.code && Intent.DANGER}
            />
          </FormGroup>
          <FormGroup
            label="New Password"
            labelInfo="*"
            labelFor="password"
            className={isSubmitting && Classes.SKELETON}
            intent={touched.password && errors.password && Intent.DANGER}
            helperText={touched.password && errors.password}
          >
            <InputGroup
              large
              id="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              intent={touched.password && errors.password && Intent.DANGER}
            />
          </FormGroup>
          <FormGroup
            label="Confirm Password"
            labelInfo="*"
            labelFor="confirm-password"
            className={isSubmitting && Classes.SKELETON}
            intent={
              touched['confirm-password'] &&
              errors['confirm-password'] &&
              Intent.DANGER
            }
            helperText={
              touched['confirm-password'] && errors['confirm-password']
            }
          >
            <InputGroup
              large
              id="confirm-password"
              type="password"
              value={values['confirm-password']}
              onChange={handleChange}
              intent={
                touched['confirm-password'] &&
                errors['confirm-password'] &&
                Intent.DANGER
              }
            />
          </FormGroup>
          {isSubmitting ? (
            <Spinner />
          ) : (
            <Button fill intent="primary" large onClick={handleSubmit}>
              Reset password
            </Button>
          )}
        </form>
      )}
    </Formik>
  </AuthLayout>
)

export default PasswordResetPageView
