import React from 'react'

interface NotEditingLinkProps {
  domain: string
  link: string
}

const NotEditingLink: React.FC<NotEditingLinkProps> = ({ domain, link }) => {
  return (
    <a
      href={`${domain}/${link}`}
      target="_blank"
      rel="noopener noreferrer"
      className="editable-singleLink"
    >
      {domain}/{link}
    </a>
  )
}

export default NotEditingLink
