import React from 'react'
import PropTypes from 'prop-types'
import { HTMLSelect } from '@blueprintjs/core'
import 'lib/ui-components/components/selects/sort-select.scss'

const SortBySelect = ({ onSelect, sortingOptions }) => {
  return (
    <div className="select-sort">
      <label htmlFor="sort" className="select-label">
        Sort by:
      </label>
      <HTMLSelect name="sort" onChange={onSelect}>
        <option value="choose">Choose an option:</option>
        {sortingOptions.map(option => (
          <option value={option.value} key={option.value}>
            {option.text}
          </option>
        ))}
      </HTMLSelect>
    </div>
  )
}

SortBySelect.propTypes = {
  onSelect: PropTypes.func,
  sortingOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
}

export default SortBySelect
