import React, { useCallback } from 'react'
import DropdownButton from 'lib/ui-components/components/buttons/DropdownButton'
import { ReactComponent as IconAucta } from 'lib/ui-components/globals/assets/icons/icon-aucta.svg'

type Props = {
  onCreate: (v3?: boolean) => Promise<void>
  loading?: boolean
}

const TrainingsListActions: React.FC<Props> = ({ onCreate, loading }) => {
  return (
    <div className="training-actions">
      <DropdownButton
        buttonIcon="cube"
        buttonText="Create a new 3D visualization"
        intent="primary"
        onClick={() => onCreate(true)}
        loading={loading}
        items={[
          {
            text: 'Use Import Editor',
            icon: 'cube',
            onClick: () => onCreate(false),
          },
          {
            text: 'Use AUCTA Web Editor',
            icon: <IconAucta />,
            onClick: useCallback(() => onCreate(true), [onCreate]),
          },
        ]}
      />
    </div>
  )
}

export default TrainingsListActions
