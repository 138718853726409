import React from 'react'
import MainLayoutContainer from 'ui/layouts/MainLayoutContainer'
import { Training } from './types'
import cx from 'classnames'
import SpinnerOverlay from 'lib/ui-components/components/spinners/SpinnerOverlay'
import TrainingDetailsOverviewV3 from './TrainingDetailsOverviewV3'

export interface TrainingDetailsViewProps {
  isStatsLoading: boolean
  isDetailsLoading: boolean
  onChangeTraining: (...args: any[]) => any
  training: Training
}

const TrainingDetailsPageViewV3: React.FC<TrainingDetailsViewProps> = ({
  isStatsLoading,
  isDetailsLoading,
  onChangeTraining,
  training,
}) => {
  return (
    <MainLayoutContainer>
      {isDetailsLoading && <SpinnerOverlay />}
      <div id="details-page" className={cx('details-page')}>
        <TrainingDetailsOverviewV3
          key={`details-${training.id}`}
          isLoading={isDetailsLoading}
          onChange={onChangeTraining}
          training={training}
        />
      </div>
    </MainLayoutContainer>
  )
}

export default TrainingDetailsPageViewV3
