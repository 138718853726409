import React from 'react'
import useImpersonatedUser from 'hooks/useImpersonatedUser'
import { User } from 'features/userAuth/types'
import Banner from 'lib/ui-components/components/banners/Banner'

interface Props {
  impersonatedUser: User
}

const UserViewBanner: React.FC<Props> = ({ impersonatedUser }) => {
  const [, setImpersonatedUser] = useImpersonatedUser()
  const restoreCurrentUser = () => {
    setImpersonatedUser(null)
  }
  return (
    <Banner
      position="top"
      status="error"
      icon="info-sign"
      className="has-animation"
      text={
        <>
          You are currently viewing
          <span className="banner-user"> {impersonatedUser.email}'s </span>
          manager, click{' '}
          <button onClick={restoreCurrentUser} className="banner-link">
            here
          </button>{' '}
          to go back
        </>
      }
    />
  )
}

export default UserViewBanner
