export const lineGraphColors = [
  '#0092ff',

  // '#003f5c',
  // '#2f4b7c',
  // '#665191',
  // '#a05195',
  // '#d45087',
  // '#f95d6a',
  // '#ff7c43',
  // '#ffa600',
]

export const stackedAreaGraphColors = [
  '#0092ff',
  '#2EE6D6',
  '#989FFF',
  '#48E3FF',

  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
]

export const compositionGraphColors = [
  '#0092ff',
  '#2ee6d6',
  '#989FFF',
  '#48e3ff',

  '#F5498B',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
]
