import React, { useState, useCallback, useEffect, useRef } from 'react'
import EditableTextElementView from 'ui/editable-elements/EditableTextElementView'

type EditableTextElementProps = {
  id?: string
  initialValue: string
  className?: string
  hasChanged?: boolean
  isLoading: boolean
  onSave: (value: string) => void | Promise<void>
  copyToClipboard?: boolean
}

const EditableTextElement: React.FC<EditableTextElementProps> = ({
  id,
  initialValue,
  className,
  isLoading,
  onSave,
  copyToClipboard,
}) => {
  const [value, setValue] = useState<string>(initialValue)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [savedFeedback, setSavedFeedback] = useState<boolean>(false)
  const firstRender = useRef(true)

  useEffect(() => {
    setValue(initialValue)
    if (initialValue === '') return
    if (firstRender.current === false) {
      setSavedFeedback(true)
      setTimeout(() => setSavedFeedback(false), 2000)
    }
    firstRender.current = false
  }, [initialValue])

  const handleChange = useCallback(
    (e: React.SyntheticEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setValue(e.currentTarget.value),
    [setValue],
  )
  return (
    <EditableTextElementView
      id={id}
      initialValue={initialValue}
      className={className}
      isLoading={isLoading}
      onSave={onSave}
      copyToClipboard={copyToClipboard}
      isEditing={isEditing}
      onEditing={setIsEditing}
      value={value}
      onSetValue={setValue}
      changeHandler={handleChange}
      savedFeedback={savedFeedback}
    />
  )
}

export default EditableTextElement
