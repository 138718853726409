import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@blueprintjs/core'
import cx from 'classnames'
import 'lib/ui-components/components/buttons/action-button.scss'

const ActionButtonLink = ({
  id,
  icon,
  text,
  selected,
  href,
  disabled,
  className,
}) => {
  return (
    <a
      id={id}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={cx('action-button', {
        'is-selected': selected,
        'is-disabled': disabled,
        [className]: className,
      })}
    >
      <Icon icon={icon} />
      <span className="text">{text}</span>
    </a>
  )
}

ActionButtonLink.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.any,
  text: PropTypes.string,
  selected: PropTypes.bool,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.any,
}

export default ActionButtonLink
