const ENV =
  process.env['REACT_APP_ENVIRONMENT'] ||
  process.env['REACT_APP_ENV'] ||
  process.env['NODE_ENV']

const environments: any = {
  debug: {
    environment: 'development',
    managerAPI: 'http://127.0.0.1:8010/proxy',
  },
  development: {
    // environment: 'development',
    // trainingsAPI:
    //   'https://gitptmilgl.execute-api.eu-central-1.amazonaws.com/dev/',
    // statsAPI: 'https://yaej1dgmyj.execute-api.eu-central-1.amazonaws.com/dev/',
  },
  production: {
    // environment: 'production',
    // trainingsAPI:
    //   'https://tn6ttvjxsd.execute-api.eu-central-1.amazonaws.com/production/',
    // statsAPI:
    //   'https://mvnm8v13fh.execute-api.eu-central-1.amazonaws.com/production',
  },
  test: {
    // environment: 'development',
    // trainingsAPI:
    //   'https://6o91y36fv4.execute-api.eu-central-1.amazonaws.com/dev/',
    // statsAPI:
    //   'https://mvnm8v13fh.execute-api.eu-central-1.amazonaws.com/production',
  },
}

export default environments[ENV] || environments.development
