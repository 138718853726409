import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Intent, H5 } from '@blueprintjs/core'
import AuthLayout from 'lib/ui-components/components/layouts/AuthLayout'
import Toaster from 'features/toasterService/Toaster'

import { logoutUserAction } from 'features/userAuth/userAuthSlice'

const LogoutPage: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    const doLogout = async () => {
      Toaster.show({
        icon: 'log-out',
        intent: Intent.WARNING,
        message: 'Session no loger active',
      })
      await dispatch(logoutUserAction())
      history.replace('/auth/login')
    }
    doLogout()
  })
  return (
    <AuthLayout project="Manager">
      <H5>Your session is closing...</H5>
    </AuthLayout>
  )
}

export default LogoutPage
