import Chart from 'react-apexcharts'
import React from 'react'
import { stackedAreaGraphColors } from 'lib/stats-graphs/utils/colors'
import {
  historyToCompositionSeries,
  calculateZoom,
} from 'lib/stats-graphs/utils/history'
import { NonIdealState } from '@blueprintjs/core'

const StackedAreaGraph = props => {
  const zoomLevel = calculateZoom(props.history)
  const series = historyToCompositionSeries(props.history)

  const setTooltipDate = value => {
    const date = new Date(value)
    const formattedDate = date.toLocaleString('en-en', {
      day: 'numeric',
      month: 'short',
    })
    return formattedDate + ' - Total of the ' + zoomLevel
  }

  const options = {
    range: zoomLevel,
    chart: {
      type: 'area',
      height: 350,
      stacked: true,
      zoom: {
        enabled: false,
      },
    },
    toolbar: {
      show: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    colors: stackedAreaGraphColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: {
      opposite: true,
    },
    tooltip: {
      fillSeriesColor: false,
      x: {
        show: true,
        format: 'dd MMM',
        formatter: value => setTooltipDate(value),
      },
    },
    legend: {
      horizontalAlign: 'left',
      fontSize: '14px',
      fontFamily: 'Inter, Arial',
      offsetY: 10,
      offsetX: -20,
      markers: {
        width: 9,
        height: 9,
      },
      itemMargin: {
        horizontal: 6,
        vertical: 2,
      },
    },
  }

  const chart =
    !props.disableHistory && props.history && series.length > 0 ? (
      <Chart options={options} series={series} type="area" height={300} />
    ) : (
      <NonIdealState
        icon="inbox-search"
        title="There is no data"
        description="No historic data available in the selected time range"
      />
    )

  return <div id="chart">{chart}</div>
}

export default StackedAreaGraph
