import React from 'react'
import cx from 'classnames'
import EditableLinkElement from 'ui/editable-elements/EditableLinkElement'
import SwitchBox from 'ui/switchs/SwitchBox'

import { REDIRECTOR_URL } from 'config/constants'

interface Props {
  onSave: any
  initialValue: any
  isLoading: boolean
  enabled: any
  link: any
  checkedSwitch: any
  onChangeSwitch: (value: any) => void
  className?: string
  domain?: string
}

const TrainingDetailsFieldLink: React.FC<Props> = ({
  onSave,
  initialValue,
  isLoading,
  enabled,
  link,
  checkedSwitch,
  onChangeSwitch,
  domain,
  className,
}) => {
  return (
    <div
      className={cx(className, 'details-field', 'details-linkInput', {
        'details-linkActive': enabled,
      })}
    >
      <p className="details-label">Link:</p>
      <EditableLinkElement
        id="detail-link-input"
        onSave={onSave}
        initialValue={initialValue}
        leftElement={
          <span className="insetLabel">{domain || REDIRECTOR_URL}/</span>
        }
        domain={domain || REDIRECTOR_URL}
        link={link}
        isLoading={isLoading}
      />
      <SwitchBox
        id="detail-enabled-switchbox"
        label="Active"
        checked={checkedSwitch}
        onChange={onChangeSwitch}
      />
    </div>
  )
}

export default TrainingDetailsFieldLink
