import { parseJwt } from 'utils/jwt'

export function isAdmin(user: any) {
  const token = user.signInUserSession.idToken.jwtToken
  const contents = parseJwt(token)
  return contents['cognito:groups']?.includes('Manager--Admin')
}

export function isSessionExpired(session: any) {
  if (!session) return false
  const now = new Date().getTime() / 1000
  return session.exp - now <= 0
}
