import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import 'lib/stats-graphs/components/panels/data-panel.scss'
import { Classes } from '@blueprintjs/core'

const DataPanel = ({ label, value, indicator, type, isLoading }) => {
  return (
    <div
      className={cx('dataPanel', {
        [Classes.SKELETON]: isLoading,
      })}
    >
      <h2 className="dataPanel-label">{label}</h2>
      <p className="dataPanel-value" title={value}>
        {value}
      </p>
      <div
        className={cx('dataPanel-indicator', { [`indicator-${type}`]: type })}
      >
        <span className="dataPanel-marker"></span>
        <span className="dataPanel-text">{indicator}</span>
      </div>
    </div>
  )
}
DataPanel.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  indicator: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error']),
  isLoading: PropTypes.bool,
}

export default DataPanel
