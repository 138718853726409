import { TrainingStats } from 'features/trainingDetails/types'

type History = { [date: string]: any }

interface EncodedItem {
  total: any
  history: History
}

export function encodeGraphData(data: TrainingStats) {
  const graphData: { [key: string]: EncodedItem } = {}
  const ammounts = ['sessions', 'groupSessions', 'sessionTime', 'VRTime']
  const averages = [
    'averageSessionTime',
    'averageGroupSessionTime',
    'averageLoadTime',
  ]
  ;[...ammounts, ...averages].forEach(k => (graphData[k] = encodeItem(data, k)))
  graphData.browsers = encodeCompositionItem(data, 'browsers')
  graphData.devices = encodeCompositionItem(data, 'devices')
  return graphData
}

function encodeCompositionItem(data: any, key: string) {
  const history = encodeHistory(data.history, key)
  const total = data.total[key]
  return {
    total,
    history,
  }
}

function encodeItem(data: any, key: string) {
  const history = encodeHistory(data.history, key)
  return {
    total: data.total[key] || 0,
    history: history,
  }
}

function encodeHistory(history: History, key: string) {
  const output: History = {}
  for (let [day, d] of Object.entries(history)) {
    if (d[key]) output[day] = d[key]
    else output[day] = 0
  }
  return output
}

export function isDataEmpty(data: any) {
  return !data.total
}
