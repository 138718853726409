import { PLAYER_URL, PLAYER_URL_V3 } from 'config/constants'
import { Training } from 'features/trainingDetails/types'
import { env } from 'lib/aucta-backend/env'
import { isV3 } from 'utils/v3'

const useEmbedLink = (training: Training) => {
  const runningInDev = env.environment === 'development'
  const v3 = isV3(training)
  const playerURL = v3 ? PLAYER_URL_V3 : PLAYER_URL
  const link =
    runningInDev && !v3
      ? `${playerURL}/?distribution=${training.distributionToken}`
      : `${playerURL}/${training.distributionToken}?`
  return link
}

export default useEmbedLink
