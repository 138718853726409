import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Toaster from 'features/toasterService/Toaster'
import UserSettingsPageView from 'features/userSettings/UserSettingsPageView'
import { SettingsFormValues } from './types'
import { deleteUser, disableUser, enableUser } from 'api/manager'
import {
  getTargetUser,
  saveCognitoAttributes,
  fetchImpersonatedUser,
  getImpersonatedUser,
  getCurrentUser,
  fetchTargetUser,
} from 'features/userAuth/userAuthSlice'
import { User, EditableCognitoAttributes } from 'features/userAuth/types'
import { pick } from 'lodash'
import useImpersonatedUser from 'hooks/useImpersonatedUser'

// -------------------------
// connection logic: commands

function useHandleSubmit() {
  const dispatch = useDispatch()
  const targetUser = useSelector(getTargetUser) as User
  const impersonatedUser = useSelector(getImpersonatedUser)

  return async (
    values: SettingsFormValues,
    { setSubmitting }: { setSubmitting?: (arg: boolean) => void } = {},
  ) => {
    if (setSubmitting) setSubmitting(true)
    const attributes: EditableCognitoAttributes =
      values as EditableCognitoAttributes
    try {
      await dispatch(saveCognitoAttributes(targetUser.sub, attributes))
      if (impersonatedUser === targetUser)
        await dispatch(fetchImpersonatedUser(impersonatedUser.sub))
      Toaster.show({
        icon: 'floppy-disk',
        intent: 'primary',
        message: 'settings saved successfully',
      })
    } catch (e) {
      Toaster.show({
        icon: 'error',
        intent: 'danger',
        message: e.message,
      })
    }
    if (setSubmitting) setSubmitting(false)
  }
}

function useHandleDelete(): [() => any, boolean, boolean] {
  const [, setImpersonatedUser] = useImpersonatedUser()
  const [isLoading, setIsLoading] = useState(false)
  const [deleted, setDeleted] = useState(false)
  return [
    async () => {
      setIsLoading(true)
      try {
        await deleteUser()
        Toaster.show({
          icon: 'floppy-disk',
          intent: 'success',
          message: 'User deleted successfully',
        })
      } catch (e) {
        Toaster.show({
          icon: 'error',
          intent: 'danger',
          message: e.message,
        })
      }
      setIsLoading(false)
      setDeleted(true)
      setImpersonatedUser(null)
    },
    isLoading,
    deleted,
  ]
}

function useHandleDisable(): [() => any, boolean, boolean] {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const targetUser = useSelector(getTargetUser)
  const isEnabled = targetUser?.isEnabled || false
  return [
    async () => {
      setIsLoading(true)
      try {
        if (isEnabled) {
          await disableUser()
        } else {
          await enableUser()
        }
        Toaster.show({
          icon: 'floppy-disk',
          intent: 'primary',
          message: 'settings saved successfully',
        })
      } catch (e) {
        Toaster.show({
          icon: 'error',
          intent: 'danger',
          message: e.message,
        })
      }
      setIsLoading(false)
      dispatch(fetchTargetUser())
    },
    isLoading,
    isEnabled,
  ]
}

// -------------------------
// ui component

const UserSettingsPage: React.FC<{}> = () => {
  const handleSubmit = useHandleSubmit()
  const user = useSelector(getCurrentUser)
  const targetUser = useSelector(getTargetUser)
  const [handleDelete, isDeleting, isDeleted] = useHandleDelete()
  const [handleDisable, isDisabling, isEnabled] = useHandleDisable()
  const initialValues = pick(targetUser, [
    'name',
    'family_name',
    'company',
    'email',
  ])

  return (
    <UserSettingsPageView
      isLoading={false}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      onUserDelete={handleDelete}
      onUserDisable={handleDisable}
      isDeleting={isDeleting}
      isDeleted={isDeleted}
      isEnabled={isEnabled}
      userIsAdmin={user?.isAdmin || false}
      targetIsAdmin={targetUser?.isAdmin || false}
    />
  )
}

export default UserSettingsPage
