import React from 'react'
import { Intent } from '@blueprintjs/core'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Toaster from 'features/toasterService/Toaster'
import LoginPageView, { LoginFormValues } from 'features/userAuth/LoginPageView'
import { loginUserAction } from 'features/userAuth/userAuthSlice'

// -------------------------
// form logic

const initialValues: LoginFormValues = {
  email: '',
  password: '',
  remember: false,
}

function useHandleLoginSubmit() {
  const dispatch = useDispatch()
  const history = useHistory()
  return async (
    values: LoginFormValues,
    { setSubmitting }: { setSubmitting: (arg: boolean) => void },
  ) => {
    setSubmitting(true)
    try {
      await dispatch(loginUserAction(values.email, values.password))
      history.push('/')
    } catch (e) {
      console.error(e)
      Toaster.show({
        icon: 'error',
        intent: Intent.DANGER,
        message: e.message,
      })
      setSubmitting(false)
    }
  }
}

// -------------------------
// ui component

const LoginPage: React.FC<{}> = () => {
  const handleLoginSubmit = useHandleLoginSubmit()
  return (
    <LoginPageView initialValues={initialValues} onSubmit={handleLoginSubmit} />
  )
}

export default LoginPage
