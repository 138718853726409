import { Button, Switch, Tab, Tabs } from '@blueprintjs/core'
import Toaster from 'features/toasterService/Toaster'
import DialogLayout from 'lib/ui-components/components/dialogs/DialogLayout'
import QRCode from 'qrcode.react'
import React, { useEffect, useMemo, useState } from 'react'

type Props = {
  isOpen: boolean
  onClose: () => void
  isLoading: boolean
  uniqueToken: string
  disableMultiuser: boolean
  embedLink: string
  isV3?: boolean
}

const DialogShareTraining: React.FC<Props> = ({
  isOpen,
  onClose,
  isLoading,
  uniqueToken,
  disableMultiuser,
  isV3,
  embedLink,
}) => {
  const [multiUserEnabled, setMultiuserEnabled] = useState<boolean>(
    !disableMultiuser,
  )
  const [audioEnabled, setAudioEnabled] = useState<boolean>(true)
  const [settingsEnabled, setSettingsEnabled] = useState<boolean>(true)

  const linkForEmbedding = useMemo(() => {
    const audioURLParam = audioEnabled ? '' : '&disable-audio=true'
    const multiUserURLParam = multiUserEnabled ? '' : '&disable-multiuser=true'
    const settingsURLParam = settingsEnabled ? '' : '&disable-settings=true'
    const iframeUrl = `${embedLink}${audioURLParam}${multiUserURLParam}${settingsURLParam}`
    const iFrameLink = `<iframe src="${iframeUrl}" width="100%" height="100%" style="border: none; min-height:400px"></iframe>`
    return iFrameLink
  }, [uniqueToken, multiUserEnabled, audioEnabled, settingsEnabled])

  useEffect(() => {
    setAudioEnabled(true)
    setSettingsEnabled(true)
    setMultiuserEnabled(!disableMultiuser)
  }, [isOpen])

  const handleCopy = (text: string) => {
    Toaster.show({
      icon: 'clipboard',
      message: 'Link copied!',
    })
    navigator.clipboard.writeText(text)
  }

  const downloadQR = () => {
    const image = document.getElementById('qr-code-image')
    const imageData = (image as HTMLCanvasElement).toDataURL()
    var imageElement = document.createElement('a')
    imageElement.href = imageData
    imageElement.download = 'AuctaTrainingQR.png'
    imageElement.click()
  }

  return (
    <DialogLayout
      isOpen={isOpen}
      onClose={onClose}
      icon="share"
      title="Share training session"
      className="dialog-share"
      isLoading={isLoading}
      body={
        <Tabs id="share_tabs" className="dialog-tabs">
          <Tab
            id="tab_qr"
            title="Share QR"
            className="dialog-tabPanel"
            panel={
              <div className="dialog-qr">
                <div className="dialog-qrBox">
                  <QRCode id="qr-code-image" value={embedLink} size={180} />
                </div>
                <div className="dialog-actions">
                  <Button onClick={() => handleCopy(embedLink)}>
                    Copy unique link
                  </Button>
                  <Button
                    intent="primary"
                    onClick={() => {
                      downloadQR()
                    }}
                  >
                    Download QR code
                  </Button>
                </div>
              </div>
            }
          />
          <Tab
            id="tab_embed"
            title="Embed"
            className="dialog-tabPanel"
            panel={
              <div className="dialog-embed">
                <pre className="dialog-embedText">{linkForEmbedding}</pre>
                {!isV3 ? (
                  <div>
                    <Switch
                      disabled={disableMultiuser}
                      checked={multiUserEnabled}
                      label="Multi-user session"
                      onChange={ev =>
                        setMultiuserEnabled(
                          (ev.target as HTMLInputElement).checked,
                        )
                      }
                    />
                    <Switch
                      checked={settingsEnabled}
                      label="Settings"
                      onChange={ev =>
                        setSettingsEnabled(
                          (ev.target as HTMLInputElement).checked,
                        )
                      }
                    />
                  </div>
                ) : null}
                <Switch
                  checked={audioEnabled}
                  label="Audio"
                  onChange={ev =>
                    setAudioEnabled((ev.target as HTMLInputElement).checked)
                  }
                />
                <div className="dialog-actions">
                  <Button onClick={() => handleCopy(embedLink)}>
                    Copy unique link
                  </Button>
                  <Button
                    intent="primary"
                    onClick={() => handleCopy(linkForEmbedding)}
                  >
                    Copy iframe code
                  </Button>
                </div>
              </div>
            }
          />
        </Tabs>
      }
      actions={false}
    />
  )
}

export default DialogShareTraining
