import React from 'react'
import { useDispatch } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import { useHistory } from 'react-router-dom'
import Toaster from 'features/toasterService/Toaster'
import RegisterPageView, {
  RegisterFormValues,
} from 'features/userAuth/RegisterPageView'

import { registerUserAction } from 'features/userAuth/userAuthSlice'

// -------------------------
// form logic

const initialValues: RegisterFormValues = {
  surname: '',
  name: '',
  company: '',
  email: '',
  password: '',
  'confirm-password': '',
}

function useHandleRegisterSubmit() {
  const dispatch = useDispatch()
  const history = useHistory()
  return async (
    values: RegisterFormValues,
    { setSubmitting }: { setSubmitting: (arg: boolean) => void },
  ) => {
    setSubmitting(true)
    try {
      await dispatch(
        registerUserAction(
          values.email,
          values.password,
          values.name,
          values.surname,
          values.company,
        ),
      )
      history.push('/auth/email-confirmation')
    } catch (e) {
      Toaster.show({
        icon: 'error',
        intent: Intent.DANGER,
        message: e.message,
      })
      setSubmitting(false)
    }
  }
}

// -------------------------
// ui component

const RegisterPage: React.FC<{}> = () => {
  const handleRegisterSubmit = useHandleRegisterSubmit()
  return (
    <RegisterPageView
      initialValues={initialValues}
      onSubmit={handleRegisterSubmit}
    />
  )
}

export default RegisterPage
