import React from 'react'
import PropTypes from 'prop-types'
import { Classes, Dialog, Spinner } from '@blueprintjs/core'
import cx from 'classnames'
import 'lib/ui-components/components/dialogs/dialogs.scss'

const DialogLayout = ({
  isOpen,
  onClose,
  icon,
  title,
  body,
  actions,
  className,
  isLoading,
  loadingText,
  status,
  spinnerIntent,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className={cx(className, 'dialog', { [`dialog-${status}`]: status })}
      icon={icon}
      lazy
      title={title}
    >
      <div className={Classes.DIALOG_BODY}>
        {isLoading ? (
          <>
            <Spinner size={40} intent={spinnerIntent} />
            {loadingText ? <p className="loading-text">{loadingText}</p> : null}
          </>
        ) : (
          body
        )}
      </div>
      {actions ? (
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>{actions}</div>
        </div>
      ) : null}
    </Dialog>
  )
}

DialogLayout.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  icon: PropTypes.any,
  title: PropTypes.string,
  body: PropTypes.node,
  actions: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isLoading: PropTypes.bool,
  delete: PropTypes.oneOfType([PropTypes.bool, PropTypes.bool]),
  loadingText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  status: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['danger', 'success']),
  ]),
  spinnerIntent: PropTypes.oneOf(['primary', 'danger']),
}

DialogLayout.defaultProps = {
  spinnerIntent: 'primary',
}

export default DialogLayout
