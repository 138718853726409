export function historyToSeries(history, labelName) {
  const zoomLevel = calculateZoom(history)
  if (Object.keys(history).length < 1) return []
  const output = {
    name: labelName,
    data: [],
  }
  const dates = getAllDatesBetween(Object.keys(history))
  for (let date of dates) {
    output.data.push([date, history[date] || 0])
  }
  if (zoomLevel === 'month') output.data = accumulateByMonth(output.data)
  if (zoomLevel === 'week') {
    output.data = accumulateByWeek(output.data)
  }
  return [output]
}

export function historyToCompositionSeries(history) {
  const zoomLevel = calculateZoom(history)
  if (Object.keys(history).length < 1) return []
  const labels = new Set()
  Object.values(history).forEach(c =>
    Object.keys(c).forEach(k => labels.add(k)),
  )
  const dates = getAllDatesBetween(Object.keys(history))
  const output = []

  for (let label of labels) {
    const data = []
    for (let date of dates) {
      const composition = history[date] || {}
      data.push([date, composition[label] || 0])
    }
    output.push({ name: label, data: data })
  }
  output.forEach(browser => {
    if (zoomLevel === 'month') browser.data = accumulateByMonth(browser.data)
    if (zoomLevel === 'week') browser.data = accumulateByWeek(browser.data)
    else return
  })
  return output
}

export function calculateZoom(history) {
  const datesSpan = getAllDatesBetween(Object.keys(history)).length
  if (datesSpan > 360) return 'month'
  if (datesSpan > 40) return 'week'
  return 'day'
}

function getAllDatesBetween(dateLocales) {
  const output = []
  const dates = dateLocales.map(l => new Date(l))
  const sorted = dates.sort((a, b) => a - b)
  const minDate = sorted[0]
  const maxDate = addDays(sorted[sorted.length - 1], 1)
  let current = minDate
  while (current.getTime() <= maxDate.getTime()) {
    output.push(formatDate(current))
    current = addDays(current, 1)
  }
  return output
}

function addDays(date, days) {
  const d = new Date(date)
  d.setDate(date.getDate() + days)
  d.setHours(0, 0, 0, 0)
  return d
}

// accumulate data by months
//

function accumulateByMonth(days) {
  const months = {}
  let lastTrackedDay = 0
  days.forEach(entry => {
    const date = new Date(entry[0])
    if (date > lastTrackedDay) lastTrackedDay = date
    const monthFirstDay = getMonthFirstDay(date)
    months[monthFirstDay] = (months[monthFirstDay] || 0) + entry[1]
  })
  const output = []
  Object.entries(months).forEach(([monthStartDate, value]) => {
    const endOfMonth = getMonthLastDay(monthStartDate)
    const endOfBin = endOfMonth > lastTrackedDay ? lastTrackedDay : endOfMonth
    output.push([monthStartDate, value])
    output.push([formatDate(endOfBin), value])
  })
  return output
}

function getMonthFirstDay(dateString) {
  const date = new Date(dateString)
  const firstDayOfMonth = new Date(date.setDate(1))
  return formatDate(firstDayOfMonth)
}

function getMonthLastDay(dateString) {
  const date = new Date(dateString)
  const firstDayNextMonth = new Date(date.setMonth(date.getMonth() + 1))
  const lastDayThisMonth = new Date(firstDayNextMonth.setDate(0))
  return lastDayThisMonth
}

// accumulate data by weeks
//

function accumulateByWeek(days) {
  const weeks = {}
  let lastTrackedDay = 0
  days.forEach(entry => {
    const date = new Date(entry[0])
    if (date > lastTrackedDay) lastTrackedDay = date
    const start = getStartOfWeek(date)
    weeks[start] = (weeks[start] || 0) + entry[1]
  })
  const output = []
  Object.entries(weeks).forEach(([dateString, value]) => {
    const endOfWeek = addDays(new Date(dateString), 6)
    const endOfBin = endOfWeek > lastTrackedDay ? lastTrackedDay : endOfWeek
    output.push([dateString, value])
    output.push([formatDate(endOfBin), value])
  })
  return output
}

function getStartOfWeek(date) {
  const dayOfWeek = date.getDay() === 0 ? 6 : date.getDay() - 1 // Monday is 0, Sunday is 6
  const startOfWeek = addDays(new Date(date), -dayOfWeek)
  return formatDate(startOfWeek)
}

function formatDate(date) {
  //The month + 1 is to adjust month value (of index 0) to real month.
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}
