import {
  currentUserToken,
  currentAuthenticatedUser,
} from 'lib/aucta-backend/auth'
import { User } from 'features/userAuth/types'

let targetUser: User | undefined | null

// @TODO: get current user from api instead of amplify

export async function getCurrentUserToken() {
  return await currentUserToken()
}

export async function getTargetUserName() {
  return targetUser?.sub || (await currentAuthenticatedUser())?.username
}

export async function getTargetUser() {
  return targetUser || (await currentAuthenticatedUser())
}

export async function getTargetUserEmail() {
  return (
    targetUser?.email || (await currentAuthenticatedUser())?.attributes.email
  )
}

export function setTargetUser(user: User | null) {
  targetUser = user
}

export function isTargetUserImpersonated() {
  return !!targetUser
}
