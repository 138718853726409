import React, { useState, useCallback, useEffect, useRef } from 'react'
import EditableLinkElementView from 'ui/editable-elements/EditableLinkElementView'

type EditableLinkElementProps = {
  id?: string
  initialValue: string
  leftElement?: any
  isLoading?: boolean
  onSave: (value: string) => void | Promise<void>
  onChange?: (_: any) => Promise<void>
  domain: string
  link: string
}

const EditableLinkElement: React.FC<EditableLinkElementProps> = ({
  id,
  initialValue,
  leftElement = undefined,
  isLoading = false,
  onSave,
  domain,
  link,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [value, setValue] = useState<string>(initialValue)
  const [savedFeedback, setSavedFeedback] = useState<boolean>(false)

  const firstRender = useRef(true)

  // keep state up to date if prop changes
  useEffect(() => {
    setValue(initialValue)
    if (initialValue === '') return
    if (firstRender.current === false) {
      setSavedFeedback(true)
      setTimeout(() => setSavedFeedback(false), 2000)
    }
    firstRender.current = false
  }, [initialValue])
  const toggleEditing = useCallback(() => setIsEditing(x => !x), [setIsEditing])
  const handleChange = useCallback(
    (e: React.SyntheticEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setValue(e.currentTarget.value),
    [setValue],
  )

  const handleSave = useCallback(() => {
    setIsEditing(false)
    if (value !== initialValue) onSave(value)
  }, [onSave, value, setIsEditing, initialValue])

  return (
    <EditableLinkElementView
      id={id}
      value={value}
      isLoading={isLoading}
      leftElement={leftElement}
      onChange={handleChange}
      domain={domain}
      link={link}
      onSave={handleSave}
      onToggleEditing={toggleEditing}
      isEditing={isEditing}
      savedFeedback={savedFeedback}
    />
  )
}

export default EditableLinkElement
