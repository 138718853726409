// export const PLAYER_URL = "https://ndm0wl1ls6.execute-api.eu-central-1.amazonaws.com/";

import { env } from 'lib/aucta-backend/env'

export const REDIRECTOR_URL = env.redirectorURL
export const REDIRECTOR_URL_V3 = env.redirectorV3URL
export const PLAYER_URL = env.playerURL
export const PLAYER_URL_V3 = env.playerV3URL
export const EDITOR_URL = env.editorURL
export const EDITOR_URL_V3 = env.editorV3URL
export const DEFAULT_TRAINING_THUMBNAIL =
  'https://content.aucta.io/media/default-training-thumbnail.png'
