import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Toaster from 'features/toasterService/Toaster'
import {
  fetchAuthenticatedUser,
  getCurrentSession,
  getCurrentUser,
} from 'features/userAuth/userAuthSlice'
import { Intent } from '@blueprintjs/core'
import { isSessionExpired } from 'lib/user'
import SpinnerOverlay from 'lib/ui-components/components/spinners/SpinnerOverlay'

const ProtectedContent: React.FC<{}> = ({ children }) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const session = useSelector(getCurrentSession)
  const user = useSelector(getCurrentUser)
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const recoverSession = async () => {
      try {
        if (isSessionExpired(session) || !user) {
          console.log('!2 fetching user')
          setLoading(true)
          await dispatch(fetchAuthenticatedUser())
          setLoading(false)
        }
      } catch (e) {
        Toaster.show({
          icon: 'lock',
          intent: Intent.WARNING,
          message: e.message || e,
        })
        history.push('/auth/login')
      }
    }
    recoverSession()
  }, [])
  return isLoading ? <SpinnerOverlay full opaque /> : <>{children}</>
}

export default ProtectedContent
