import React from 'react'
import {
  Intent,
  Classes,
  Spinner,
  FormGroup,
  InputGroup,
  Button,
} from '@blueprintjs/core'
import { Formik } from 'formik'
import AuthLayout from 'lib/ui-components/components/layouts/AuthLayout'
import validate from 'validate.js'

// -------------------------
// types

export interface PasswordRecoveryFormValues {
  email: string
}

interface PasswordRecoveryPageViewProps {
  initialValues: PasswordRecoveryFormValues
  onSubmit: (values: PasswordRecoveryFormValues, _: any) => void
}

// -------------------------
// form validation

const constraints = {
  email: { presence: { allowEmpty: false }, email: true },
}

function validateForm(values: PasswordRecoveryFormValues) {
  return validate(values, constraints)
}

// -------------------------
// ui component

const PasswordRecoveryPageView: React.FC<PasswordRecoveryPageViewProps> = ({
  initialValues,
  onSubmit,
}) => (
  <AuthLayout
    project="Manager"
    title="Reset password"
    subtitle="Further instructions will be sent to your email."
  >
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }: any) => (
        <form>
          <FormGroup
            label="Email Address"
            labelFor="email"
            className={isSubmitting && Classes.SKELETON}
            intent={errors.email && Intent.DANGER}
            helperText={errors.email && errors.email[0]}
          >
            <InputGroup
              large
              id="email"
              placeholder="john.smith@acme.com"
              type="email"
              value={values.email}
              onChange={handleChange}
              intent={errors.email && Intent.DANGER}
            />
          </FormGroup>
          {isSubmitting ? (
            <Spinner intent="primary" />
          ) : (
            <Button fill intent="primary" large onClick={handleSubmit}>
              Send email
            </Button>
          )}
        </form>
      )}
    </Formik>
  </AuthLayout>
)

export default PasswordRecoveryPageView
