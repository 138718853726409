import React from 'react'
import { RadioGroup } from '@blueprintjs/core'
import Radio from 'lib/ui-components/components/form-elements/Radio'
import 'lib/ui-components/components/time-selector/time-selector.scss'

const TimeSelector = ({ onChange, selectedValue }) => {
  return (
    <RadioGroup
      className="time-selector"
      onChange={onChange}
      selectedValue={selectedValue}
    >
      <Radio
        name="time-period"
        label="1 year"
        value="1-year"
        onChange={onChange}
        checked={selectedValue === '1-year'}
      />
      <Radio
        name="time-period"
        label="6 months"
        value="6-months"
        onChange={onChange}
        checked={selectedValue === '6-months'}
      />
      <Radio
        name="time-period"
        label="3 months"
        value="3-months"
        onChange={onChange}
        checked={selectedValue === '3-months'}
      />
      <Radio
        name="time-period"
        label="1 month"
        value="1-month"
        onChange={onChange}
        checked={selectedValue === '1-month'}
      />
      <Radio
        name="time-period"
        label="1 week"
        value="1-week"
        onChange={onChange}
        checked={selectedValue === '1-week'}
      />
      <Radio
        className="time-radio"
        name="time-period"
        label="1 day"
        value="1-day"
        onChange={onChange}
        checked={selectedValue === '1-day'}
      />
    </RadioGroup>
  )
}

export default TimeSelector
