import React, { useState, useCallback, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@blueprintjs/core'
import cx from 'classnames'
import { ReactComponent as IconCopy } from 'lib/ui-components/globals/assets/icons/icon-copy.svg'
import 'lib/ui-components/components/buttons/copy-clipboard-button.scss'

const ACTIVATED_DURATION = 1000

const CopyToClipboardButton = ({ text, id }) => {
  const timerId = useRef(null)
  const [isActivated, setActivated] = useState(false)
  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(text)
    setActivated(true)
    timerId.current = setTimeout(() => {
      setActivated(false)
    }, ACTIVATED_DURATION)
  }, [text])
  useEffect(() => () => clearTimeout(timerId.current), [])

  return (
    <Button
      id={id}
      className={cx('copy-clipboard-button button-icon', {
        activated: isActivated,
      })}
      icon={isActivated ? 'tick' : <IconCopy />}
      onClick={handleCopy}
      title="Copy to clipboard"
    />
  )
}

CopyToClipboardButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  text: PropTypes.string,
}

export default CopyToClipboardButton
