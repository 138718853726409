export function formatDuration(seconds) {
  let output = ''
  if (seconds < 1) return `${Math.floor(seconds * 1000)}ms`
  if (seconds >= 86400) output += `${Math.floor(seconds / 86400)}d `
  if (seconds >= 3600) output += `${Math.floor((seconds % 86400) / 3600)}h `

  if (seconds >= 86400) return output

  if (seconds >= 60) output += `${Math.floor((seconds % 3600) / 60)}m `

  if (seconds >= 3600) return output

  if (seconds % 60 > 0) output += `${(seconds % 60).toFixed(2)}s `
  return output
}

export function secondsToHoursMinSec(seconds) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor(seconds / 60 - hours * 60)
  const secondsString = `${seconds - minutes * 60 - hours * 3600}s`
  const hoursString = hours > 0 ? `${hours}h ` : ''
  const mimnutesString = minutes > 0 || hours > 0 ? `${minutes}m ` : ''
  return hoursString + mimnutesString + secondsString
}
