import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import HeaderLogos from 'lib/ui-components/components/headers/HeaderLogos'
import 'lib/ui-components/components/headers/header.scss'

const HeaderContainer = ({ className, topSlot, children, project }) => {
  return (
    <header className={cx('header', className)}>
      {topSlot ? topSlot : null}
      <div className="wrapper">
        <HeaderLogos project={project} />
        {children ? children : null}
      </div>
    </header>
  )
}

HeaderContainer.propTypes = {
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  topSlot: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  project: PropTypes.string,
}

export default HeaderContainer
