import React from 'react'
import PropTypes from 'prop-types'
import 'lib/ui-components/components/headings/page-heading.scss'

const PageHeading = ({ leftSlot, title, children }) => {
  return (
    <div className="page-heading">
      {leftSlot}
      <h2 className="page-title">{title}</h2>
      {children ? children : null}
    </div>
  )
}

PageHeading.propTypes = {
  title: PropTypes.string,
  leftSlot: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  children: PropTypes.node,
}

export default PageHeading
