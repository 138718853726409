import { createSlice } from '@reduxjs/toolkit'
import { retrieveCustomerStats } from 'lib/aucta-backend/stats/api'
import { RootState } from 'app/rootReducer'
import { AppThunk } from 'app/store'
import { getTargetUserName } from 'api/credentials'

const initialState = {
  data: {},
}

const stats = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
  },
})

export default stats

// -------------------------
// selectors

export const getData = (state: RootState) => state.stats.data

// -------------------------
// async actions

export const fetchData =
  (range: number): AppThunk =>
  async dispatch => {
    const customer = await getTargetUserName()
    const data = await retrieveCustomerStats(range, customer)
    data.customerName = customer
    dispatch(stats.actions.setData(data))
  }
