import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from '@blueprintjs/core'
import 'lib/ui-components/components/spinners/spinners.scss'
import cx from 'classnames'

const SpinnerOverlay = ({ intent, opaque, full }) => {
  return (
    <div
      className={cx('spinner-overlay', {
        'is-opaque': opaque,
        'is-full': full,
      })}
    >
      <Spinner intent={intent} />
    </div>
  )
}

SpinnerOverlay.propTypes = {
  intent: PropTypes.oneOf(['none', 'primary', 'success', 'warning', 'danger']),
  opaque: PropTypes.bool,
  full: PropTypes.bool,
}

SpinnerOverlay.defaultProps = {
  intent: 'primary',
}

export default SpinnerOverlay
