import React from 'react'
import { Button } from '@blueprintjs/core'
import DialogLayout from 'lib/ui-components/components/dialogs/DialogLayout'

type Props = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  locked: boolean
  isLoading: boolean
}

const DialogLockTraining: React.FC<Props> = ({
  isOpen,
  onClose,
  locked,
  onConfirm,
  isLoading,
}) => {
  return (
    <DialogLayout
      isOpen={isOpen}
      onClose={onClose}
      icon={locked ? 'unlock' : 'lock'}
      title={`${locked ? 'Unlock' : 'Lock'} training session`}
      isLoading={isLoading}
      body={
        <>
          <p>
            Are you sure you want to {locked ? 'unlock' : 'lock'} this training
            session?
          </p>
          <p>You {locked ? 'will' : "won't"} be able to edit or delete it</p>
        </>
      }
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            id="detail-lock-confirm-button"
            onClick={onConfirm}
            intent="primary"
          >
            {locked ? 'Unlock' : 'Lock'} training session
          </Button>
        </>
      }
    />
  )
}

export default DialogLockTraining
