import axios from 'axios'
import { currentUserToken } from 'lib/aucta-backend/auth'
import { env as environment } from 'lib/aucta-backend/env'
import { getTargetUserName } from './credentials'
import { EditableCognitoAttributes } from 'features/userAuth/types'
import { logCritical } from 'lib/aucta-backend/log'

export interface EditableTrainingProps {
  title?: string
  description?: string
  customToken?: string
  enabled?: boolean
  locked?: boolean
  disableMultiUser?: boolean
}

export async function getUsers(): Promise<any[]> {
  const userToken = await currentUserToken()
  const response = await axios.get('/users', {
    baseURL: environment.managerAPI,
    headers: { Authorization: userToken },
  })
  const users = response.data.Users
  return users
}

export async function getUser(userId: string) {
  const userToken = await currentUserToken()
  if (!userId) return
  const response = await axios.get(`/u/${userId}/user`, {
    baseURL: environment.managerAPI,
    headers: { Authorization: userToken },
  })
  return response.data
}

export async function updateUserAttributes(
  userId: string,
  attributes: EditableCognitoAttributes,
) {
  const userToken = await currentUserToken()
  const response = await axios.put(`/u/${userId}/user/attributes`, attributes, {
    baseURL: environment.managerAPI,
    headers: { Authorization: userToken },
  })
  return response.data
}

export async function deleteUser() {
  const userToken = await currentUserToken()
  const activeUser = await getTargetUserName()
  const response = await axios.delete(`/u/${activeUser}/user`, {
    baseURL: environment.managerAPI,
    headers: { Authorization: userToken },
  })
  return response.data
}

export async function enableUser() {
  const userToken = await currentUserToken()
  const activeUser = await getTargetUserName()
  const response = await axios.post(
    `/u/${activeUser}/user/enable`,
    {},
    {
      baseURL: environment.managerAPI,
      headers: { Authorization: userToken },
    },
  )
  return response.data
}

export async function disableUser() {
  const userToken = await currentUserToken()
  const activeUser = await getTargetUserName()
  const response = await axios.post(
    `/u/${activeUser}/user/disable`,
    {},
    {
      baseURL: environment.managerAPI,
      headers: { Authorization: userToken },
    },
  )
  return response.data
}

export async function getTrainings() {
  const userToken = await currentUserToken()
  const activeUser = await getTargetUserName()
  const response = await axios.get(`/u/${activeUser}/trainings`, {
    baseURL: environment.managerAPI,
    headers: { Authorization: userToken },
  })
  return response.data
}

export async function createTraining(id: string, title?: string, v3 = false) {
  const userToken = await currentUserToken()
  const activeUser = await getTargetUserName()

  try {
    const response = await axios.post(
      `/u/${activeUser}/trainings${v3 ? '/v3' : ''}`,
      { title: title || 'New training', id: id },
      {
        baseURL: environment.managerAPI,
        headers: { Authorization: userToken },
      },
    )
    console.log('*> created training', response)
  } catch (err) {
    logCritical(
      `Could not create training: ${err.message}`,
      'Manager',
      'createTraining',
    )
    throw err
  }
}

export async function copyTraining(
  id: string,
  newId: string,
  targetUser?: string,
) {
  const userToken = await currentUserToken()
  const activeUser = await getTargetUserName()
  try {
    const response = await axios.post(
      `/u/${activeUser}/trainings/copy`,
      { id, newId, targetUser },
      {
        baseURL: environment.managerAPI,
        headers: { Authorization: userToken },
      },
    )
    console.log('*> copied training', response)
  } catch (err) {
    logCritical(
      `Could not copy training: ${err.message}`,
      'Manager',
      'copyTraining',
    )
    throw err
  }
}

export async function deleteTraining(id: string) {
  const userToken = await currentUserToken()
  const activeUser = await getTargetUserName()
  try {
    const response = await axios.delete(`/u/${activeUser}/trainings/${id}`, {
      baseURL: environment.managerAPI,
      headers: { Authorization: userToken },
    })
    console.log('*> delete training:', id)
    return response?.data
  } catch (err) {
    logCritical(
      `Could not delete training: ${err.message}`,
      'Manager',
      'deleteTraining',
    )
    throw err
  }
}

export async function getTraining(id: string) {
  const userToken = await currentUserToken()
  const activeUser = await getTargetUserName()
  const response = await axios.get(`/u/${activeUser}/trainings/${id}`, {
    baseURL: environment.managerAPI,
    headers: { Authorization: userToken },
  })
  //@TODO: check why the api always returns status 200.
  if (!response.data) throw new Error('Invalid training')
  return response.data
}

export async function getTrainingStats(id: string) {
  const userToken = await currentUserToken()
  const activeUser = await getTargetUserName()
  const response = await axios.get(`/u/${activeUser}/trainings/${id}/stats`, {
    baseURL: environment.managerAPI,
    headers: { Authorization: userToken },
  })
  return response.data
}

export async function updateTraining(
  id: string,
  values: EditableTrainingProps,
) {
  const userToken = await currentUserToken()
  const activeUser = await getTargetUserName()
  const response = await axios.put(`/u/${activeUser}/trainings/${id}`, values, {
    baseURL: environment.managerAPI,
    headers: { Authorization: userToken },
  })
  return response.data
}
