import React from 'react'
import VideoWrapper from 'ui/videos/VideoWrapper'

const VideoTutorial: React.FC = () => {
  return (
    <VideoWrapper
      title="Aucta Manager tutorial"
      width="800"
      height="450"
      url="https://www.youtube.com/embed/fRILQX89Mg8"
    />
  )
}

export default VideoTutorial
