import React from 'react'
import AuthLayout from 'lib/ui-components/components/layouts/AuthLayout'
import {
  FormGroup,
  InputGroup,
  Button,
  Classes,
  Spinner,
  Intent,
} from '@blueprintjs/core'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import validate from 'validate.js'

// -------------------------
// types

export interface RegisterFormValues {
  surname: string
  name: string
  company: string
  email: string
  password: string
  'confirm-password': string
}

interface RegisterPageViewProps {
  initialValues: RegisterFormValues
  onSubmit: (values: RegisterFormValues, _: any) => void
}

// -------------------------
// form validation

const constraints = {
  surname: { presence: { allowEmpty: false } },
  name: { presence: { allowEmpty: false } },
  company: { presence: { allowEmpty: false } },
  email: { presence: { allowEmpty: false }, email: true },
  password: { presence: true, length: { minimum: 6, maximum: 10 } },
  'confirm-password': { equality: 'password' },
}

function validateRegisterForm(values: RegisterFormValues) {
  return validate(values, constraints)
}

// -------------------------
// ui component

const RegisterPageView: React.FC<RegisterPageViewProps> = ({
  initialValues,
  onSubmit,
}) => (
  <AuthLayout title="Register" subtitle="Create new account" project="Manager">
    <Formik
      initialValues={initialValues}
      validate={validateRegisterForm}
      onSubmit={onSubmit}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
      }: any) => (
        <form>
          <FormGroup
            label="Surname"
            labelInfo="*"
            labelFor="surname"
            className={isSubmitting && Classes.SKELETON}
            intent={touched.surname && errors.surname && Intent.DANGER}
            helperText={touched.surname && errors.surname}
          >
            <InputGroup
              id="surname"
              placeholder="Smith"
              value={values.surname}
              onChange={handleChange}
              intent={touched.surname && errors.surname && Intent.DANGER}
              large
            />
          </FormGroup>
          <FormGroup
            label="Name"
            labelInfo="*"
            labelFor="name"
            className={isSubmitting && Classes.SKELETON}
            intent={touched.name && errors.name && Intent.DANGER}
            helperText={touched.name && errors.name}
          >
            <InputGroup
              id="name"
              placeholder="John"
              value={values.name}
              onChange={handleChange}
              intent={touched.name && errors.name && Intent.DANGER}
              large
            />
          </FormGroup>
          <FormGroup
            label="Company"
            labelInfo="*"
            labelFor="company"
            className={isSubmitting && Classes.SKELETON}
            intent={touched.company && errors.company && Intent.DANGER}
            helperText={touched.company && errors.company}
          >
            <InputGroup
              id="company"
              placeholder="Acme Inc."
              value={values.company}
              onChange={handleChange}
              intent={touched.company && errors.company && Intent.DANGER}
              large
            />
          </FormGroup>
          <FormGroup
            label="Email Address"
            labelInfo="*"
            labelFor="email"
            className={isSubmitting && Classes.SKELETON}
            intent={touched.email && errors.email && Intent.DANGER}
            helperText={touched.email && errors.email && errors.email[0]}
          >
            <InputGroup
              id="email"
              placeholder="john.smith@acme.com"
              type="email"
              value={values.email}
              onChange={handleChange}
              intent={touched.email && errors.email && Intent.DANGER}
              large
            />
          </FormGroup>
          <FormGroup
            label="Password"
            labelInfo="*"
            labelFor="password"
            className={isSubmitting && Classes.SKELETON}
            intent={touched.password && errors.password && Intent.DANGER}
            helperText={touched.password && errors.password}
          >
            <InputGroup
              id="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              intent={touched.password && errors.password && Intent.DANGER}
              large
            />
          </FormGroup>
          <FormGroup
            label="Confirm Password"
            labelInfo="*"
            labelFor="confirm-password"
            className={isSubmitting && Classes.SKELETON}
            intent={
              touched['confirm-password'] &&
              errors['confirm-password'] &&
              Intent.DANGER
            }
            helperText={
              touched['confirm-password'] && errors['confirm-password']
            }
          >
            <InputGroup
              id="confirm-password"
              type="password"
              value={values['confirm-password']}
              onChange={handleChange}
              large
              intent={
                touched['confirm-password'] &&
                errors['confirm-password'] &&
                Intent.DANGER
              }
            />
          </FormGroup>
          {isSubmitting ? (
            <Spinner />
          ) : (
            <Button fill intent="primary" large onClick={handleSubmit}>
              Sign Up
            </Button>
          )}
        </form>
      )}
    </Formik>
    <Link className="auth-link" to="/auth/login">
      I already have an account
    </Link>
  </AuthLayout>
)

export default RegisterPageView
