import React from 'react'
import { Link } from 'react-router-dom'
import { NonIdealState } from '@blueprintjs/core'
import MainLayoutContainer from 'ui/layouts/MainLayoutContainer'

const NotFoundMessageView: React.FC<{}> = () => (
  <MainLayoutContainer>
    <NonIdealState
      className="message"
      icon="delete"
      title="Nothing to see here"
      description="The page you're trying to access is empty or the address is malformed."
      action={<Link to="/">Go back to the home page</Link>}
    />
  </MainLayoutContainer>
)

export default NotFoundMessageView
