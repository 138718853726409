import Chart from 'react-apexcharts'
import React from 'react'
import { lineGraphColors } from 'lib/stats-graphs/utils/colors'
import { historyToSeries, calculateZoom } from 'lib/stats-graphs/utils/history'
import { NonIdealState } from '@blueprintjs/core'
import {
  formatDuration,
  secondsToHoursMinSec,
} from 'lib/stats-graphs/utils/time'

let colors = [...lineGraphColors]
const getColor = () => {
  if (colors.length === 0) colors = [...lineGraphColors]
  return colors.shift()
}

const LineGraph = props => {
  const zoomLevel = calculateZoom(props.history)
  const series = historyToSeries(props.history, props.labelName)
  const color = getColor()
  const formatData = (data, isTooltip = false) => {
    if (!props.labelName.includes('Total session time'))
      return data.toFixed(2).replace(/[.,]00$/, '')
    if (isTooltip) return secondsToHoursMinSec(data)
    return formatDuration(data)
  }

  const setTooltipDate = value => {
    const date = new Date(value)
    const formattedDate = date.toLocaleString('en-en', {
      day: 'numeric',
      month: 'short',
    })
    return formattedDate + ' - Total of the ' + zoomLevel
  }

  const options = {
    range: zoomLevel,
    chart: {
      toolbar: {
        show: false,
      },
      type: 'area',
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    fill: {
      colors: [color],
    },
    colors: [color],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: {
      opposite: true,
      labels: {
        formatter: data => formatData(data),
      },
    },
    tooltip: {
      fillSeriesColor: false,
      x: {
        show: true,
        format: 'dd MMM',
        formatter: value => setTooltipDate(value),
      },
      y: {
        formatter: data => formatData(data, true),
      },
    },
    legend: {
      horizontalAlign: 'left',
      fontSize: '14px',
      fontFamily: 'Inter, Arial',
      markers: {
        width: 9,
        height: 9,
      },
    },
  }

  const chart =
    !props.disableHistory && props.history && series.length > 0 ? (
      <Chart options={options} series={series} type="area" height={260} />
    ) : (
      <NonIdealState
        icon="inbox-search"
        title="There is no data"
        description="No historic data available in the selected time range"
      />
    )

  return <div id="chart">{chart}</div>
}

export default LineGraph
