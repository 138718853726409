import React from 'react'
import { H4 } from '@blueprintjs/core'

// -------------------------
// types

type Props = {
  title: string
  subtitle?: string
  children: any
}

// -------------------------
// ui component

const SettingsCol: React.FC<Props> = ({ title, subtitle, children }) => {
  return (
    <div className="settings-col">
      <H4>{title}</H4>
      {subtitle ? <p className="settings-subtitle">{subtitle}</p> : null}
      {children}
    </div>
  )
}

export default SettingsCol
